import React from "react";
import PropTypes from "prop-types";

const SvgError = props => {
  const { color,name, ...rest } = props;
  return (
    <svg width={18} height={18} {...rest}>
      <defs>
        <path
              d="M1 10c0-4.964 4.036-9 9-9s9 4.036 9 9c0 4.963-4.037 9-9 9s-9-4.037-9-9zm2 0c0 3.859 3.141 7 7 7s7-3.141 7-7c0-3.86-3.14-7-7-7s-7 3.14-7 7zm7-1.414l2.828-2.829 1.415 1.415L11.414 10l2.829 2.828-1.415 1.415L10 11.414l-2.828 2.829-1.415-1.415L8.586 10 5.757 7.172l1.415-1.415L10 8.586z"
              id={"error_svg__a" + name}
        />
      </defs>
      <g transform="translate(-1 -1)" fill="none" fillRule="evenodd">
           <mask id={"error_svg__b" + name} fill="#fff">
              <use xlinkHref={"#error_svg__a" + name} />
        </mask>
           <g mask={"url(#error_svg__b" + name +")"}  fill={color}>
          <path d="M0 0h20v20H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgError.propTypes = {
   color: PropTypes.string.isRequired,
   name:PropTypes.string
};

export default SvgError;
