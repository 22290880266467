import React from "react";
import PropTypes from "prop-types";

const SvgLogOut = props => {
  const { color, ...rest } = props;
  return (
    <svg width={19} height={18} {...rest}>
      <defs>
        <path
          d="M16.156 9L14 6.228 15.579 5l3.977 5.114-3.977 5.114L14 14l2.333-3H9V9h7.156zM3 3v14h8v-3h2v5H1V1h12v5h-2V3H3z"
          id="log-out_svg__a"
        />
      </defs>
      <g transform="translate(-1 -1)" fill="none" fillRule="evenodd">
        <mask id="log-out_svg__b" fill="#fff">
          <use xlinkHref="#log-out_svg__a" />
        </mask>
        <g mask="url(#log-out_svg__b)" fill={color}>
          <path d="M0 0h20v20H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgLogOut.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgLogOut;
