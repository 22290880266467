import React from "react";
import PropTypes from "prop-types";

const SvgDown = props => {
  const { color, ...rest } = props;
  return (
    <svg width={12} height={6} {...rest}>
      <defs>
        <path
          id="down_svg__a"
          d="M13.5 6.3l1.059 1.456-5.48 3.985L3.6 7.756 4.659 6.3l4.42 3.215z"
        />
      </defs>
      <g transform="translate(-3 -6)" fill="none" fillRule="evenodd">
        <mask id="down_svg__b" fill="#fff">
          <use xlinkHref="#down_svg__a" />
        </mask>
        <g mask="url(#down_svg__b)" fill={color}>
          <path d="M.9.9h16.2v16.2H.9z" />
        </g>
      </g>
    </svg>
  );
};

SvgDown.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgDown;
