import React from "react";
import PropTypes from "prop-types";

const SvgDelete = props => {
  const { color, ...rest } = props;
  return (
    <svg width={12} height={12} {...rest}>
      <defs>
        <path
          d="M8.629 10.043L4.086 5.5 5.5 4.086l4.543 4.543 4.543-4.543L16 5.5l-4.543 4.543L16 14.586 14.586 16l-4.543-4.543L5.5 16l-1.414-1.414 4.543-4.543z"
          id="delete_svg__a"
        />
      </defs>
      <g transform="translate(-4 -4)" fill="none" fillRule="evenodd">
        <mask id="delete_svg__b" fill="#fff">
          <use xlinkHref="#delete_svg__a" />
        </mask>
        <g mask="url(#delete_svg__b)" fill={color}>
          <path d="M0 0h20v20H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgDelete.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgDelete;
