import React from "react";
import PropTypes from "prop-types";

const SvgBond = props => {
  const { color, ...rest } = props;
  return (
    <svg width={15} height={18} {...rest}>
      <defs>
        <path
          d="M3 17h7v2H3v-2zm10-5h3v2h-3v-2zm0-7h3v2h-3V5zm2.644 14h-2.288L11 14.76V1h7v13.76L15.644 19zM13 14.24l1.5 2.7 1.5-2.7V3h-3v11.24z"
          id="bond_svg__a"
        />
      </defs>
      <g transform="translate(-3 -1)" fill="none" fillRule="evenodd">
        <mask id="bond_svg__b" fill="#fff">
          <use xlinkHref="#bond_svg__a" />
        </mask>
        <g mask="url(#bond_svg__b)" fill={color}>
          <path d="M0 0h20v20H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgBond.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgBond;
