/* eslint-disable no-plusplus */
import React from "react";
import { Table, Search, Popup } from "semantic-ui-react";
import { Pagination, Button } from "antd";
import PropTypes from "prop-types";
import _ from "lodash";
import "./OspreyTable.scss";
import {
  dateChecker,
  dateformatter,
} from "../../helperFunctions/dateFormatter";
import AlliedIcon from "../AlliedIcon/AlliedIcon";

const replaceAllItems = (value, from, to) => {
  if (typeof value.replaceAll === "function") {
    return value.replaceAll(from, to);
  }
  return value.split(from).join(to);
};

export const PAGE_LIMIT = 10;
/**
 * Handles filtering of data in the table
 */
class OspreyTable extends React.Component {
  constructor(props) {
    super(props);
    const { rows, rowIds, headers, defaultPage, selectedIndex, renderingPage } =
      this.props;
    const offset = (defaultPage - 1) * PAGE_LIMIT;
    this.state = {
      searchValue: "",
      column: null,
      direction: null,
      currSortInd: -1,
      sortableHeaderInfo: this.setHeaders(),
      page: defaultPage,
      paginatedRows: rows.slice(offset, offset + PAGE_LIMIT),
      paginatedIds: rowIds.slice(offset, offset + PAGE_LIMIT),
      rows,
      rowIds,
      selectedIndex,
      renderingPage,
    };
    this.currentHeaders = headers;
    this.renderTable = this.renderTable.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    const { rows, rowIds, searchValue, selectedIndex, page } = this.state;

    const offset =
      this.props.page > 0
        ? (nextProps.page - 1) * PAGE_LIMIT
        : (page - 1) * PAGE_LIMIT;
    if (JSON.stringify(rows) !== JSON.stringify(nextProps.rows)) {
      if (searchValue.length <= 0) {
        const currentRows = nextProps.rows.slice(offset, offset + PAGE_LIMIT);
        this.setState({
          rows: nextProps.rows,
          paginatedRows: currentRows,
          column: null,
        });
      }
    }
    if (JSON.stringify(rowIds) !== JSON.stringify(nextProps.rowIds)) {
      if (searchValue.length <= 0) {
        const currentIds = nextProps.rowIds.slice(offset, offset + PAGE_LIMIT);
        this.setState({
          rowIds: nextProps.rowIds,
          paginatedIds: currentIds,
          column: null,
        });
      }
    }

    if (
      JSON.stringify(selectedIndex) !== JSON.stringify(nextProps.selectedIndex)
    ) {
      this.setState({
        selectedIndex: nextProps.selectedIndex,
      });
    }
  }

  componentDidUpdate = () => {
    const { headers, page } = this.props;
    if (this.currentHeaders !== headers) {
      this.currentHeaders = headers;
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        sortableHeaderInfo: this.setHeaders(),
      });
    }

    if (page > 0 && this.state.page !== page) {
      this.setState({ page });
    }
  };

  /**
   * Modifies header data to incorporate potential filter icon
   */
  setHeaders = () => {
    const { sortableHeaders, headers } = this.props;
    const sortableHeaderInfo = [];
    headers.map((header) =>
      sortableHeaders.includes(header)
        ? sortableHeaderInfo.push({ header, icon: "sort" })
        : sortableHeaderInfo.push({ header, icon: "none" })
    );
    return sortableHeaderInfo;
  };

  sortTogether = (headerIndex, clickedColumn, headers, ind) => {
    const { rows, rowIds } = this.state;
    let zipped = [];
    let i;

    for (i = 0; i < rows.length; ++i) {
      zipped.push({
        rows: rows[i],
        rowIds: rowIds[i],
      });
    }

    if (headerIndex !== undefined) {
      zipped = zipped.sort((a, b) => {
        const first = a.rows[headerIndex];
        const second = b.rows[headerIndex];

        if (first === second) {
          return 0;
        }
        if (this.isNullOrEmpty(first) || this.isNullOrEmpty(second)) {
          return !first ? 1 : -1;
        }
        if (dateChecker(first)) {
          return new Date(first) - new Date(second);
        }
        if (!Number.isFinite(first) && !Number.isFinite(second)) {
          if (typeof first === "string" && typeof second === "string") {
            return first.toLowerCase().localeCompare(second.toLowerCase());
          }
          if (first.props !== null && second.props !== null) {
            if (first.props.value === second.props.value) return 0;
            if (first.props.value !== second.props.value) {
              return first.props.value ? -1 : 1;
            }
          }
        }

        return first < second ? -1 : 1;
      });
    }

    const rowArray = [];
    const rowIdArray = [];

    for (i = 0; i < zipped.length; ++i) {
      rowArray.push(zipped[i].rows);
      rowIdArray.push(zipped[i].rowIds);
    }
    this.setState({
      column: clickedColumn,
      sortableHeaderInfo: headers,
      currSortInd: ind,
      rows: rowArray,
      rowIds: rowIdArray,
      page: 1,
      paginatedRows: rowArray.slice(0, PAGE_LIMIT),
      paginatedIds: rowIdArray.slice(0, PAGE_LIMIT),
    });
  };

  isNullOrEmpty = (str) => {
    return str === undefined || str === null || str.length === 0;
  };

  itemRender = (current, type, originalElement) => {
    const { page, rows } = this.state;

    if (type === "prev") {
      return <Button disabled={page === 1}>Back</Button>;
    }
    if (type === "next") {
      return (
        <Button
          disabled={
            rows.length === 0 || page === Math.ceil(rows.length / PAGE_LIMIT)
          }
        >
          Next
        </Button>
      );
    }

    return originalElement;
  };

  /**
   * Handles sorting different types of data in table
   * @param {Object}: event
   */
  handleSort = (clickedColumn) => {
    const { column, sortableHeaderInfo, currSortInd, rows, rowIds } =
      this.state;
    const { headers } = this.props;
    const sortedHeaders = sortableHeaderInfo;
    let ind = 0;

    if (column !== clickedColumn) {
      sortedHeaders.forEach((h, i) => {
        if (h.header === clickedColumn) {
          sortedHeaders[i].icon = "down";
          ind = i;
        } else if (sortedHeaders[i].icon !== "none") {
          sortedHeaders[i].icon = "sort";
        }
      });

      let headerIndex;
      headers.forEach((h, i) => {
        if (clickedColumn === h) {
          headerIndex = i;
        }
      });

      const newArray = [];
      rows.forEach((row) => {
        newArray.push(row[headerIndex]);
      });

      // If the header exists, then we can sort on it
      this.sortTogether(headerIndex, clickedColumn, sortedHeaders, ind);
      return;
    }

    if (sortedHeaders[currSortInd].icon === "down") {
      sortedHeaders[currSortInd].icon = "up";
    } else {
      sortedHeaders[currSortInd].icon = "down";
    }

    const reversedRows = rows.reverse();
    const reversedIds = rowIds.reverse();

    this.setState({
      sortableHeaderInfo: sortedHeaders,
      rows: reversedRows,
      rowIds: reversedIds,
      page: 1,
      paginatedRows: reversedRows.slice(0, PAGE_LIMIT),
      paginatedIds: reversedIds.slice(0, PAGE_LIMIT),
    });
  };

  /**
   * Called when search active selection
   * index is changed
   * @param {Object}: event
   * @param {Object}: data
   */
  handleSearchResultSelectionChange = (event, data) => {
    const {
      filter: { onSelectionChange },
    } = this.props;
    if (onSelectionChange) {
      onSelectionChange(event, data);
    }
  };

  /**
   * Called on search input change
   * @param {Object}: event
   * @param {Object}: data
   */
  handleSearchChange = (event, data) => {
    const {
      filter: { onSearchChange },
    } = this.props;

    if (onSearchChange) {
      onSearchChange(event, data);
    } else {
      this.setState({
        searchValue: data.value,
      });

      this.handleFilter(data.value);
    }
  };

  /**
   * Called when a searched result is selected
   * @param {Object}: event
   * @param {Object}: data
   */
  handleSearchResultSelect = (event, data) => {
    const {
      filter: { onResultSelect },
    } = this.props;

    if (onResultSelect) {
      onResultSelect(event, data);
    }
  };

  handlePageChange = (page, pageSize) => {
    const { handlePageChange } = this.props;
    const { rows, rowIds } = this.state;
    const offset = (page - 1) * pageSize;
    const currentRows = rows.slice(offset, offset + pageSize);
    const currentIds = rowIds.slice(offset, offset + pageSize);
    if (handlePageChange) {
      handlePageChange(page);
    }
    this.setState({
      paginatedRows: currentRows,
      paginatedIds: currentIds,
      page,
    });
  };

  /**
   * Handles filtering of data in the table
   */
  handleFilter(dataValue) {
    const filteredArray = [];
    const filteredIndices = [];
    const newRowIds = [];
    const { rows, filter, rowIds } = this.props;
    let input = dataValue;

    if (dataValue && Number.isInteger(Number(dataValue[0]))) {
      input = replaceAllItems(replaceAllItems(dataValue, "/", ""), "-", "");
    }

    rows.forEach((row, index) => {
      row.forEach((val, ind) => {
        let term = val;

        if (val && Number.isInteger(Number(val[0]))) {
          const date = Date.parse(term);
          term = replaceAllItems(replaceAllItems(val, "/", ""), "-", "");
          if (date) {
            term = term.substring(4, 8) + term.substring(0, 4);
          }
        }

        if (val && val.props && val.props.children) {
          term = val.props.children;
        }

        if (filter.filterIndices) {
          if (filter.filterIndices.indexOf(ind) !== -1) {
            if (
              String(term).toLowerCase().indexOf(input.toLowerCase()) !== -1
            ) {
              // If the row isn't already filtered
              if (!filteredArray.includes(row)) {
                filteredArray.push(row);
                filteredIndices.push(index);
              }
            }
          }
        } else if (
          String(term).toLowerCase().indexOf(input.toLowerCase()) !== -1
        ) {
          // If the row isn't already filtered
          if (!filteredArray.includes(row)) {
            filteredArray.push(row);
            filteredIndices.push(index);
          }
        }
      });
    });

    filteredIndices.forEach((f) => newRowIds.push(rowIds[f]));
    this.setState({ rows: filteredArray, rowIds: newRowIds }, () =>
      this.handlePageChange(1, PAGE_LIMIT)
    );
  }

  renderPrefix = (index, row) => {
    const { rowInFocus, optionText, icons } = this.props;
    const { paginatedIds } = this.state;

    if (paginatedIds !== null && paginatedIds[index] === rowInFocus) {
      return <AlliedIcon icon="rightArrow" />;
    }

    const icon = icons.find((e) => e.id === paginatedIds[index]);
    if (icon) return <AlliedIcon icon={icon.name} />;
    return typeof optionText === "function"
      ? optionText(row, paginatedIds ? paginatedIds[index] : null)
      : optionText;
  };

  /**
   * Returns a formatted table
   */
  renderTable() {
    const {
      filter,
      rowClickEvent,
      additionalOptions,
      displayTimeFlag,
      headerFocus,
      defaultPage,
      renderingPage,
    } = this.props;
    const {
      column,
      direction,
      sortableHeaderInfo,
      loading,
      results,
      value,
      searchValue,
      paginatedRows,
      paginatedIds,
      rows,
      page,
      selectedIndex,
    } = this.state;
    let headerKey = 0;
    let rowKey = 0;
    let colKey = 0;

    const filterField = (
      <Search
        id={`${renderingPage}__OspreyTable_Search--table-filter`}
        open={false}
        loading={loading || false}
        size="mini"
        className="ocl-table-filter"
        input={{ placeholder: filter.placeholderText, iconPosition: "left" }}
        onResultSelect={this.handleSearchResultSelect}
        onSearchChange={this.handleSearchChange}
        onSelectionChange={this.handleSearchResultSelectionChange}
        results={results || []}
        value={value || searchValue}
      />
    );

    return (
      <div className="ocl-table-body">
        {filter !== null && (
          <div
            className="ocl-table-filter"
            style={
              filter.align === "left" ? { float: "left" } : { float: "right" }
            }
          >
            {!filter.tooltip ? (
              filterField
            ) : (
              <Popup
                content={<h4>{filter.tooltip}</h4>}
                trigger={filterField}
              />
            )}
          </div>
        )}
        {additionalOptions !== null && (
          <div
            className="ocl-additionalOptionsGroup"
            style={
              additionalOptions.align === "left"
                ? { float: "left" }
                : { float: "right" }
            }
          >
            {additionalOptions.optionList.map((option) => (
              <div className="ocl-additionalOption">{option}</div>
            ))}
          </div>
        )}
        <Table
          className="ocl-table"
          sortable
          unstackable
          celled
          verticalAlign="center"
        >
          <Table.Header>
            <Table.Row className="ocl-table-header-row">
              {rowClickEvent !== null ? (
                <Table.HeaderCell
                  className="ocl-header editable"
                  key={headerKey++}
                />
              ) : null}
              {sortableHeaderInfo.map((h) =>
                h.icon !== "none" ? (
                  <Table.HeaderCell
                    sorted={column === h.header ? direction : null}
                    onClick={() => this.handleSort(h.header)}
                    key={headerKey++}
                    className="ocl-header"
                  >
                    <div className="ocl-sortable-header">
                      {h.header}
                      <AlliedIcon className="ocl-sort-icon" icon={h.icon} />
                    </div>
                  </Table.HeaderCell>
                ) : (
                  <Table.HeaderCell
                    className={`ocl-header ${
                      headerFocus ? "header-focus" : ""
                    }`}
                    key={headerKey++}
                  >
                    {h.header}
                  </Table.HeaderCell>
                )
              )}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {paginatedRows.map((row, index) => (
              <Table.Row
                id={paginatedIds !== null ? paginatedIds[index] : rowKey}
                onClick={(e) => rowClickEvent(e, row, index)}
                style={rowClickEvent !== null ? { cursor: "pointer" } : null}
                className={
                  index === parseInt(selectedIndex.toString(), 10) &&
                  selectedIndex > -1
                    ? "selected-row"
                    : "ocl-row"
                }
                key={rowKey++}
              >
                {rowClickEvent !== null ? (
                  <Table.Cell className="ocl-col editable" key={rowKey++}>
                    <div className="ocl-table-onHover" />
                    <div className="ocl-table-edit">
                      {this.renderPrefix(index, row)}
                    </div>
                  </Table.Cell>
                ) : null}
                {row.map((col) => (
                  <Table.Cell
                    className="ocl-col"
                    key={colKey++}
                    style={
                      col && _.includes(col, "@")
                        ? { wordBreak: "break-all" }
                        : {}
                    }
                    textAlign="middle"
                  >
                    {dateChecker(col)
                      ? dateformatter(col, displayTimeFlag)
                      : col}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <div className="pagination-container">
          <Pagination
            current={page}
            total={rows.length}
            className="table-pagination"
            pageSize={PAGE_LIMIT}
            onChange={this.handlePageChange}
            defaultCurrent={defaultPage}
            itemRender={this.itemRender}
            disabled={rows.length === 0}
          />
        </div>
      </div>
    );
  }

  render() {
    return this.renderTable();
  }
}

OspreyTable.propTypes = {
  /** Header information */
  headers: PropTypes.arrayOf(PropTypes.string),
  /** Column information */
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)),
  /** List of flags for which headers to be sortable  */
  sortableHeaders: PropTypes.arrayOf(PropTypes.string),
  /** Click event for each row */
  rowClickEvent: PropTypes.func,
  /** Id values for each row */
  rowIds: PropTypes.arrayOf(PropTypes.string),
  /** Properties of the filter field  */
  filter: PropTypes.shape({
    /** Click event triggered on selecting result  */
    onResultSelect: PropTypes.func,
    /** Click event triggered on searching */
    onSearchChange: PropTypes.func,
    /** Click event triggered on changing the selection */
    onSelectionChange: PropTypes.func,
    /** Default text to be displayed in filter field */
    placeholderText: PropTypes.string,
    /** Flag for aligning Filter to right or left */
    align: PropTypes.oneOf(["right", "left"]),
    /** Optional message to be displayed on hover of filter */
    tooltip: PropTypes.string,
    /** Optional indices to filter by */
    filterIndices: PropTypes.arrayOf(PropTypes.any),
  }),
  /** Any additional UI options necessary (buttons, etc) to be added on top of table */
  additionalOptions: PropTypes.shape({
    /** List of additional option elements to display */
    optionList: PropTypes.arrayOf(PropTypes.any),
    /** Flag for aligning options to right or left */
    align: PropTypes.oneOf(["right", "left"]),
  }),
  /** Allows for custom option text on modifiable rows */
  optionText: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  /** Display time along with date if true */
  displayTimeFlag: PropTypes.bool,
  /** ID of the row that is currently in focus */
  rowInFocus: PropTypes.number,
  /** Flag to add bold on-hover text to header */
  headerFocus: PropTypes.bool,
  /**  Array of objects with an ID and Icon name */
  icons: PropTypes.arrayOf(PropTypes.object),
  /** Default page of pagination */
  defaultPage: PropTypes.number,
  /** Current page of pagination */
  page: PropTypes.number,
  /** Click event on changing pagination */
  handlePageChange: PropTypes.func,
  /** Default selected index */
  selectedIndex: PropTypes.string,
  /** The page rendering the control so ID's can be set for UI tracking */
  renderingPage: PropTypes.string,
};

OspreyTable.defaultProps = {
  headers: ["Header1", "Header2", "Header3"],
  rows: [
    [1, 2, 3],
    [7, 4, 3],
    [5, 8, 3],
  ],
  sortableHeaders: ["Header2"],
  rowClickEvent: null,
  filter: {
    placeholderText: "Filter",
    align: "right",
  },
  rowIds: null,
  additionalOptions: null,
  displayTimeFlag: false,
  rowInFocus: -1,
  headerFocus: false,
  icons: [],
  defaultPage: 1,
  page: 0,
  handlePageChange: null,
  selectedIndex: 0,
  renderingPage: "",
};

export default OspreyTable;
