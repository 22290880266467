import React from "react";
import PropTypes from "prop-types";

const SvgCautionLarge = props => {
  const { color, ...rest } = props;
  return (
    <svg width={36} height={36} {...rest}>
      <defs>
        <path
          d="M18 36C8.059 36 0 27.941 0 18S8.059 0 18 0s18 8.059 18 18-8.059 18-18 18zm0-2c8.837 0 16-7.163 16-16S26.837 2 18 2 2 9.163 2 18s7.163 16 16 16zM17 8h2v14h-2V8zm0 17h2v2h-2v-2z"
          id="cautionLarge_svg__a"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="cautionLarge_svg__b" fill="#fff">
          <use xlinkHref="#cautionLarge_svg__a" />
        </mask>
        <g mask="url(#cautionLarge_svg__b)" fill={color}>
          <path d="M0 0h36v36H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgCautionLarge.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgCautionLarge;
