import React from "react";

import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { Header, Icon } from "../osprey-component-library";
import { WELCOME_ROUTE } from "../constants";

const Unauthorized = ({ reason, showLink, disableHeight }) => (
  <Header
    as="h2"
    icon
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      height: disableHeight ? "initial" : "100vh",
    }}
  >
    <Icon name="ban" />
    Unauthorized
    <Header.Subheader>{reason}</Header.Subheader>
    {showLink && (
      <Header.Subheader>
        <Link to={WELCOME_ROUTE} />
      </Header.Subheader>
    )}
  </Header>
);

Unauthorized.propTypes = {
  showLink: PropTypes.bool,
  reason: PropTypes.string,
  disableHeight: PropTypes.bool,
};

Unauthorized.defaultProps = {
  showLink: true,
  reason: "You are not authorized to visit this page.",
  disableHeight: false,
};

export default Unauthorized;
