/* eslint-disable no-unused-expressions */
import React from "react";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";
import { TimePicker } from "antd";
import moment from "moment";
import Calendar from "../Calendar";
import * as Styled from "./styles";
import { getDateISO } from "../helpers/calendar";
import AlliedIcon from "../../AlliedIcon/AlliedIcon";
import AlliedButton from "../../AlliedButton/AlliedButton";
import "./Datepicker.scss";
import { dateChecker, dateformatter } from "../../../helperFunctions/dateFormatter";

class Datepicker extends React.Component {
  constructor(props) {
    super(props);
    const { value } = props;
    this.state = {
      date: value,
      calendarOpen: false,
      timeOpen: false,
      time: moment("00:00", "hh:mm A"),
      isValid: false
    };
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value !== value) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ date: value });
    }
  }

  toggleCalendar = () => this.setState(prevState => ({ calendarOpen: !prevState.calendarOpen }));

  toggleTime = () => this.setState(prevState => ({ timeOpen: !prevState.timeOpen }));

  handleTimeControl = open => {
    this.setState({ timeOpen: open });
  };

  handleTimeChange = time => {
    this.setState({ time });
  };

  handleChange = evt => {
    this.handleDateRender(evt.target.value);
  };

  handleDateChange = date => {
    const { onDateChanged, type, minDate } = this.props;
    const { timeOpen } = this.state;
    let newDate = date ? getDateISO(date) : null;

    if (newDate) {
      newDate = new Date(newDate.setHours(0, 0, 0, 0));
    }
    newDate = dateformatter(newDate, timeOpen);

    if (minDate && newDate < minDate) {
      newDate = minDate;
    }
    // eslint-disable-next-line react/destructuring-assignment
    this.state.date !== newDate &&
      this.setState({ date: newDate, calendarOpen: type === "datetime" }, () => {
        // eslint-disable-next-line react/destructuring-assignment
        typeof onDateChanged === "function" && onDateChanged(this.state.date);
      });
  };

  handleDateTimeConfirm = () => {
    const { date, time } = this.state;
    const { onDateTimeConfirm } = this.props;

    const momentDate = moment(date);
    momentDate.set({
      hour: time.hour(),
      minute: time.minutes(),
      second: 0,
      millisecond: 0
    });

    this.setState({ calendarOpen: false });

    if (typeof onDateTimeConfirm === "function") {
      onDateTimeConfirm(momentDate.toDate());
    }
  };

  handleDateRender = value => {
    const { type, onDateChanged } = this.props;
    const { time } = this.state;
    const date = new Date(value);
    if (type === "datetime") {
      const momentDate = moment(date);
      momentDate.set({
        hour: time.hour(),
        minute: time.minutes(),
        second: 0,
        millisecond: 0
      });

      return momentDate.format("M/DD/YY h:mm A");
    }

    if (dateChecker(value, true)) {
      this.setState(
        {
          isValid: true,
          date: dateformatter(value)
        },
        () => {
          onDateChanged(this.state.date);
        }
      );
    } else {
      this.setState(
        {
          isValid: false,
          date: value
        },
        () => {
          onDateChanged(this.state.date);
        }
      );
    }
  };

  // Used to supress Uncaught Type error from ReactStrap
  // If Toggle is passed null or undefined in Styled.DatePickerDropdown
  emptyFunction = () => {
    return null;
  };

  updateDate = date => {
    this.setState(date);
  };

  render() {
    const {
      label,
      type,
      fluid,
      as,
      buttonText,
      position,
      buttonDisabled,
      buttonStyle,
      minDate,
      isDateEnabled
    } = this.props;
    const { calendarOpen, time, timeOpen, date } = this.state;

    return (
      <Styled.DatePickerContainer>
        {label !== undefined &&
          label !== null &&
          label.length > 0 && <Styled.DatePickerLabel>{label}</Styled.DatePickerLabel>}
        {as === "button" && (
          <React.Fragment>
            <AlliedButton
              onClick={this.toggleCalendar}
              color="blue"
              disabled={buttonDisabled === true}
              style={{ ...buttonStyle }}
            >
              {buttonText}
            </AlliedButton>
            <Styled.DatePickerDropdown
              isOpen={calendarOpen}
              toggle={type === "datetime" ? this.emptyFunction : this.toggleCalendar}
            >
              <Styled.DatePickerDropdownToggle />
              <Styled.DatePickerDropdownMenu position={position}>
                {calendarOpen && (
                  <div>
                    <Calendar
                      date={date && new Date(date)}
                      minDate={minDate}
                      onDateChanged={this.handleDateChange}
                      isDateEnabled={isDateEnabled}
                    />
                    {type === "datetime" && (
                      <div>
                        <TimePicker
                          open={timeOpen}
                          onOpenChange={this.handleTimeControl}
                          value={time}
                          onChange={this.handleTimeChange}
                          format="hh:mm A"
                          use12Hours
                          allowClear={false}
                          style={{ width: "100%" }}
                          addon={() => (
                            <Button size="small" primary fluid onClick={this.toggleTime}>
                              Ok
                            </Button>
                          )}
                        />
                        <Button.Group fluid>
                          <Button
                            size="small"
                            primary
                            onClick={this.handleDateTimeConfirm}
                            className="no-border-radius cp-btn-green-sm"
                          >
                            Okay
                          </Button>
                          <Button
                            className="no-border-radius cp-btn-blue-sm"
                            size="small"
                            onClick={this.toggleCalendar}
                          >
                            Cancel
                          </Button>
                        </Button.Group>
                      </div>
                    )}
                  </div>
                )}
              </Styled.DatePickerDropdownMenu>
            </Styled.DatePickerDropdown>
          </React.Fragment>
        )}
        {as === "input" && (
          <React.Fragment>
            <Styled.DatePickerInput
              type="text"
              value={date}
              onChange={this.handleChange}
              placeholder="mm/dd/yyyy"
              onClick={this.toggleCalendar}
              fluid={fluid}
              icon={
                <i className="icon date-input-icon">
                  <AlliedIcon icon="calendar" />
                </i>
              }
            />
            <Styled.DatePickerDropdown
              isOpen={calendarOpen}
              toggle={type === "datetime" ? this.emptyFunction : this.toggleCalendar}
            >
              <Styled.DatePickerDropdownToggle />
              <Styled.DatePickerDropdownMenu position={position}>
                {calendarOpen && (
                  <div>
                    <Calendar
                      date={date && new Date(date)}
                      minDate={minDate}
                      onDateChanged={this.handleDateChange}
                      isDateEnabled={isDateEnabled}
                    />
                    {type === "datetime" && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column"
                        }}
                      >
                        <TimePicker
                          open={timeOpen}
                          onOpenChange={this.handleTimeControl}
                          value={time}
                          onChange={this.handleTimeChange}
                          format="hh:mm A"
                          use12Hours
                          allowClear={false}
                          style={{ width: "100%" }}
                          addon={() => (
                            <Button size="small" type="primary" onClick={this.toggleTime}>
                              Ok
                            </Button>
                          )}
                        />
                        <Button.Group>
                          <Button
                            size="small"
                            primary
                            onClick={this.handleDateTimeConfirm}
                            className="no-border-radius cp-btn-green-sm"
                          >
                            Okay
                          </Button>
                          <Button
                            className="no-border-radius cp-btn-blue-sm"
                            size="small"
                            onClick={this.toggleCalendar}
                          >
                            Cancel
                          </Button>
                        </Button.Group>
                      </div>
                    )}
                  </div>
                )}
              </Styled.DatePickerDropdownMenu>
            </Styled.DatePickerDropdown>
          </React.Fragment>
        )}
      </Styled.DatePickerContainer>
    );
  }
}

Datepicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onDateChanged: PropTypes.func,
  isDateEnabled: PropTypes.func,
  type: PropTypes.string,
  as: PropTypes.string,
  buttonText: PropTypes.string,
  position: PropTypes.oneOf(["top", "bottom"]),
  onDateTimeConfirm: PropTypes.func,
  fluid: PropTypes.bool,
  buttonDisabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  buttonStyle: PropTypes.object
};

Datepicker.defaultProps = {
  value: new Date(),
  label: null,
  type: "date",
  as: "input",
  position: "bottom",
  fluid: false,
  onDateTimeConfirm: null,
  buttonText: "",
  onDateChanged: null,
  isDateEnabled: null,
  buttonDisabled: null,
  buttonStyle: null
};

export default Datepicker;
