﻿import React from "react";
import PropTypes from "prop-types";
import "../styles/AlliedCheckBox.scss";

class AlliedCheckBox extends React.Component {
  checkUpdate(e) {
    const { name, checked, onChange } = this.props;
    if (e.target.checked !== checked) {
      onChange(name, e.target.checked);
    }
  }

  render() {
    const { name, label, checked, disabled } = this.props;
    return (
      <div>
        <label className="allied-checkbox">
          <input
            name={name}
            checked={checked}
            type="checkbox"
            onChange={e => this.checkUpdate(e)}
            disabled={disabled}
          />
          <span className="input-text">{label}</span>
        </label>
      </div>
    );
  }
}

AlliedCheckBox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

AlliedCheckBox.defaultProps = {
  label: "Test Label",
  checked: false,
  disabled: false,
  onChange: () => null
};

export default AlliedCheckBox;
