import React from "react";
import PropTypes from "prop-types";

const SvgCalendar = props => {
  const { color, uuid, ...rest } = props;
  return (
    <svg width={18} height={18} {...rest}>
      <defs>
        <path
          d="M3 17h14V7H3v10zm0 2H1V5h18v14H3zM5 2h10V1h2v1h2v2H1V2h2V1h2v1zm0 7h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9z"
          id="calendar_svg__a"
        />
      </defs>
      <g transform="translate(-1 -1)" fill="none" fillRule="evenodd">
        <mask id={`calendar_${uuid}`} fill="#fff">
          <use xlinkHref="#calendar_svg__a" />
        </mask>
        <g mask={`url(#calendar_${uuid})`} fill={color}>
          <path d="M0 0h20v20H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgCalendar.propTypes = {
  color: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired
};

export default SvgCalendar;
