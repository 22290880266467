import React from "react";
import PropTypes from "prop-types";

const SvgVerifyData = props => {
  const { color, ...rest } = props;
  return (
    <svg width={16} height={18} {...rest}>
      <defs>
        <path
          d="M7 3v14h9V9h2v10H3.949L3 18.316l-1-3L2.949 14H5V1h8v2H7zM4.67 17H5v-1h-.664l.333 1zM15.92 2l1.78.912-4.313 8.414-1.771.017L9 6.468l1.762-.945 1.715 3.195L15.92 2z"
          id="verify-data_svg__a"
        />
      </defs>
      <g transform="translate(-2 -1)" fill="none" fillRule="evenodd">
        <mask id="verify-data_svg__b" fill="#fff">
          <use xlinkHref="#verify-data_svg__a" />
        </mask>
        <g mask="url(#verify-data_svg__b)" fill={color}>
          <path d="M0 0h20v20H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgVerifyData.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgVerifyData;
