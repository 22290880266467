import React from "react";
import PropTypes from "prop-types";

const SvgFilter = props => {
  const { color, ...rest } = props;
  return (
    <svg width={18} height={16} {...rest}>
      <defs>
        <path
          d="M8.414 8.293L8.707 9v6.382l2-1V9L11 8.293 15.293 4H4.12l4.293 4.293zM6.707 9.414L1 3.707 1.707 2h16l.707 1.707-5.707 5.707V15l-.553.894-4 2L6.707 17V9.414z"
          id="filter_svg__a"
        />
      </defs>
      <g transform="translate(-1 -2)" fill="none" fillRule="evenodd">
        <mask id="filter_svg__b" fill="#fff">
          <use xlinkHref="#filter_svg__a" />
        </mask>
        <g mask="url(#filter_svg__b)" fill={color}>
          <path d="M0 0h20v20H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgFilter.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgFilter;
