import moment from "moment";

const acceptedFormat = /^(((0)[0-9])|((1)[0-2]))(-|\/|)([0-2][0-9]|(3)[0-1])(-|\/|)\d{4}$/;
export const DEFAULT_DATE = "0001-01-01T00:00:00";

/** Checks a string for valid date format */
export function dateChecker(dateAsString, testFormat = false) {
  // eslint-disable-next-line no-restricted-globals

  if (testFormat && acceptedFormat.test(dateAsString)) {
    return true;
  }

  if (isNaN(dateAsString)) {
    return moment(dateAsString, moment.ISO_8601, true).isValid();
  }

  return false;
}
 
/** Formats the date to the proper standard */
export function dateformatter(date, displayTimeFlag = false) {
  let dateUTC = moment.utc(date);
  if (!dateUTC._isValid) {
    dateUTC = moment(date, "MMDDYYYY");
  }

  const timeCheck = moment.utc(date).format("hh:mm");
  if (timeCheck !== "12:00") {
    // has time component in date
    return dateUTC.local().format(displayTimeFlag ? "MM/DD/YYYY @ hh:mm A" : "MM/DD/YYYY");
  }

  // no time component in date
  return dateUTC.utc().format("MM/DD/YYYY");
}

/**
 * Checks if the date is DEFAULT_DATE
 * @param {any} date
 */
export function isDefaultDate(date) {  
   if ((date === dateformatter(DEFAULT_DATE) )|| ( dateformatter(date) === dateformatter(DEFAULT_DATE) ) ){
      return true;
  }
  return false;   
}