﻿import React from "react";
import PropTypes from "prop-types";
import { Grid, Form, TextArea } from "semantic-ui-react";

const RequestOverrideModal = props => {
  const { override, handleTextChange } = props;

  return (
    <Grid columns={1}>
      <Grid.Column>
        <Form>
          <b>Provide a reason for the Override request:</b>
          <TextArea
            name="override"
            placeholder="Override reason..."
            value={override}
            onChange={handleTextChange}
          />
        </Form>
      </Grid.Column>
    </Grid>
  );
};

RequestOverrideModal.propTypes = {
  override: PropTypes.string,
  handleTextChange: PropTypes.func
};

RequestOverrideModal.defaultProps = {
  override: "",
  handleTextChange: null
};

export default RequestOverrideModal;
