import React from "react";
import PropTypes from "prop-types";

const SvgRefresh = props => {
  const { color, ...rest } = props;
  return (
    <svg width={14} height={17} {...rest}>
      <defs>
        <path
          d="M10 16v2.385l-.8.459-4-3.442v-.917l4-3.441.8.458V14a5 5 0 10-5-5H3a7 7 0 117 7z"
          id="refresh_svg__a"
        />
      </defs>
      <g transform="translate(-3 -2)" fill="none" fillRule="evenodd">
        <mask id="refresh_svg__b" fill="#fff">
          <use xlinkHref="#refresh_svg__a" />
        </mask>
        <g mask="url(#refresh_svg__b)" fill={color}>
          <path d="M0 0h20v20H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgRefresh.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgRefresh;
