import React from "react";
import PropTypes from "prop-types";

const SvgClockMedium = props => {
  const { color, ...rest } = props;
  return (
    <svg width={22} height={22} {...rest}>
      <defs>
        <path
          d="M12 23C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11zm0-2a9 9 0 100-18 9 9 0 000 18zm1-10h4v2h-6V5h2v6z"
          id="clockMedium_svg__a"
        />
      </defs>
      <g transform="translate(-1 -1)" fill="none" fillRule="evenodd">
        <mask id="clockMedium_svg__b" fill="#fff">
          <use xlinkHref="#clockMedium_svg__a" />
        </mask>
        <g mask="url(#clockMedium_svg__b)" fill={color}>
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgClockMedium.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgClockMedium;
