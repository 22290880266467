import React, { Component } from "react";
import { Search, Label } from "semantic-ui-react";
import PropTypes from "prop-types";
import axios from "axios";
import _ from "lodash";
import AlliedIcon from "../AlliedIcon/AlliedIcon";
import "./ClientSearch.scss";

class ClientSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      clients: [],
      value: "",
      results: [],
    };
  }

  componentDidMount() {
    const { independent } = this.props;
    this.fetchClients();

    if (!independent)
      window.addEventListener("clientCodeUpdate", this.handleClientUpdate);
  }

  componentWillUnmount() {
    const { independent } = this.props;
    if (!independent)
      window.removeEventListener("clientCodeUpdate", this.handleClientUpdate);
  }

  fetchClients = async () => {
    const { includeAllied, independent } = this.props;
    axios
      .get(`/api/navigation/GetClientCodes`, {
        params: { includeAllied },
      })
      .then((response) => {
        const { data } = response;
        this.setState({
          clients: data,
        });
        if (includeAllied) {
          const client = data[0];
          this.setState({ value: `${client.code} - ${client.name}` });
          if (!independent) this.dispatchCustomEvent();
          else
            this.handleClientCodeSelect(null, {
              result: {
                code: client.code,
                name: client.name,
              },
            });
        } else {
          const clientdisplay = localStorage.getItem("clientDisplay");
          if (clientdisplay && clientdisplay !== "1 - Allied") {
            this.setState({ value: clientdisplay });
          } else {
            this.setState({ value: "" });
          }
        }
      });
  };

  handleClientCodeSelect = (e, { result }) => {
    const { onClientUpdate, independent } = this.props;

    const clientDisplay = `${result.code} - ${result.name}`;
    if (!independent) {
      localStorage.setItem("clientCode", result.code);
      localStorage.setItem("clientDisplay", clientDisplay);
      localStorage.setItem("clientName", result.name);
      this.dispatchCustomEvent();
    } else {
      onClientUpdate(result);
    }
    this.setState({ value: clientDisplay });
  };

  dispatchCustomEvent = () => {
    const event = new CustomEvent("clientCodeUpdate");
    window.dispatchEvent(event);
  };

  handleClientCodeChange = (e, { value }) => {
    const { onClientUpdate } = this.props;
    const { clients } = this.state;

    this.setState({ loading: true, value });

    if (value.length < 1) {
      this.setState({ loading: false, results: [], value: "" });
      localStorage.setItem("clientCode", "");
      localStorage.setItem("clientDisplay", "");
      localStorage.setItem("clientName", "");
      onClientUpdate();
      return;
    }

    const re = new RegExp(_.escapeRegExp(value), "i");
    const isMatch = (client) => re.test(`${client.code} - ${client.name}`);

    this.setState({
      loading: false,
      results: _.filter(clients, isMatch),
    });
  };

  resultClientCodeRenderer = (client) => (
    <Label content={`${client.code} - ${client.name}`} />
  );

  handleClientUpdate = () => {
    const { onClientUpdate } = this.props;
    const client = localStorage.getItem("clientDisplay");
    const code = localStorage.getItem("clientCode");
    const name = localStorage.getItem("clientName");
    this.setState({ value: client });
    if (onClientUpdate) onClientUpdate({ code, name });
  };

  render() {
    const { includeAllied, onClientUpdate, independent, fluidInput, ...rest } =
      this.props;
    const { loading, results, value } = this.state;
    return (
      <Search
        id="OspreyComponentLibrary__ClientSearch--nav-client-select"
        className="nav-client-search"
        placeholder="Select a Client"
        loading={loading}
        onResultSelect={this.handleClientCodeSelect}
        onSearchChange={this.handleClientCodeChange}
        results={results}
        value={value}
        resultRenderer={this.resultClientCodeRenderer}
        input={{ fluid: fluidInput }}
        icon={
          <i
            className="icon"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AlliedIcon icon="search" />
          </i>
        }
        {...rest}
      />
    );
  }
}

ClientSearch.propTypes = {
  includeAllied: PropTypes.bool,
  onClientUpdate: PropTypes.func,
  independent: PropTypes.bool,
  fluidInput: PropTypes.bool,
};

ClientSearch.defaultProps = {
  independent: false,
  includeAllied: false,
  onClientUpdate: null,
  fluidInput: false,
};

export default ClientSearch;
