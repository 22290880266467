export const AuthActions = {
  "SET_AUTH": "SET_AUTH",
};

export const AuthReducer = (state, action) => {
  switch (action.type) {
    case AuthActions.SET_AUTH:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const InitialAuthState = { isAuthenticated: null, securityFunctions: [] };
