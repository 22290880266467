import React from "react";
import PropTypes from "prop-types";
import { Form, Input } from "semantic-ui-react";
import OptionallyDisplayed from "./OptionallyDisplayed";
import "./ValidatedInput.scss";
import AlliedIcon from "../AlliedIcon/AlliedIcon";
import { AlliedColors } from "../AlliedIcon/icons";

class ValidatedInput extends React.Component {
  constructor(props) {
    super(props);
    this.shouldDisplayError = this.shouldDisplayError.bind(this);
  }

  shouldDisplayError() {
    const { showError, errorText } = this.props;
    return showError && errorText !== "";
  }
  render() {
    const {
      type,
      placeholder,
      value,
      onChange,
      errorText,
      name,
      label,
      disabled,
      className,
      icon,
      iconPosition,
      onBlur,
      onFocus
    } = this.props;
    const errClassName = this.shouldDisplayError() ? `ErrorBorder ${className}` : className;

    return (
      <div className="form-field text-field">
        {label ? (
          <Form.Input
            icon={icon}
            iconPosition={iconPosition}
            fluid
            type={type || "text"}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            name={name}
            label={label}
            className={errClassName}
            disabled={disabled ? value : undefined}
            onBlur={onBlur}
            onFocus={onFocus}
          />
        ) : (
          <Input
            icon={icon}
            iconPosition={iconPosition}
            fluid
            type={type || "text"}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            name={name}
            className={errClassName}
            disabled={disabled ? value : undefined}
            onBlur={onBlur}
            onFocus={onFocus}
          />
        )}
        <OptionallyDisplayed display={this.shouldDisplayError()}>
          <div className="validation-error">
            <AlliedIcon className="icon-align" icon="error" color={AlliedColors.error} />
            <span className="error-text">{errorText}</span>
          </div>
        </OptionallyDisplayed>
      </div>
    );
  }
}

ValidatedInput.propTypes = {
  showError: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  errorText: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.object,
  iconPosition: PropTypes.string,
  className: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func
};

ValidatedInput.defaultProps = {
  errorText: "",
  type: null,
  placeholder: "",
  value: null,
  name: "",
  label: "",
  disabled: false,
  icon: null,
  iconPosition: null,
  className: "",
  onBlur: null,
  onFocus: null
};

export default ValidatedInput;
