import React from "react";
import PropTypes from "prop-types";

const SvgPersonMedium = props => {
  const { color, ...rest } = props;
  return (
    <svg width={16} height={22} {...rest}>
      <defs>
        <path
          d="M12.001 11a5 5 0 110-10 5 5 0 010 10zm0-2a3 3 0 100-6 3 3 0 000 6zM6 20.99h12.001v-6.236l-2.43-.694-3.646 1.577-2.598-1.574-3.327.74v6.187zm9.432-9.05l4.57 1.306v9.744H4v-9.792l5.676-1.261 2.402 1.455 3.354-1.452z"
          id="personMedium_svg__a"
        />
      </defs>
      <g transform="translate(-4 -1)" fill="none" fillRule="evenodd">
        <mask id="personMedium_svg__b" fill="#fff">
          <use xlinkHref="#personMedium_svg__a" />
        </mask>
        <g mask="url(#personMedium_svg__b)" fill={color}>
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgPersonMedium.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgPersonMedium;
