import React from "react";
import PropTypes from "prop-types";

const SvgPointOfSale = props => {
  const { color, ...rest } = props;
  return (
    <svg width={16} height={19} {...rest}>
      <defs>
        <path
          d="M2 1h16v11.46l-8 6.857-8-6.857V1zm8 15.683l6-5.143V3H4v8.54l6 5.143zM7 5h6v2H7V5zm0 4h6v2H7V9z"
          id="point-of-sale_svg__a"
        />
      </defs>
      <g transform="translate(-2 -1)" fill="none" fillRule="evenodd">
        <mask id="point-of-sale_svg__b" fill="#fff">
          <use xlinkHref="#point-of-sale_svg__a" />
        </mask>
        <g mask="url(#point-of-sale_svg__b)" fill={color}>
          <path d="M0 0h20v20H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgPointOfSale.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgPointOfSale;
