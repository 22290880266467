import React, { PureComponent } from "react";
import PropTypes from "prop-types";

export default class AlliedSwitch extends PureComponent {
  static propTypes = {
    id: PropTypes.string,
    onClick: PropTypes.func,
    checked: PropTypes.bool,
    inputProps: PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      checked: PropTypes.bool.isRequired,
      onChange: PropTypes.func
    })
  };

  static defaultProps = {
    id: "",
    onClick: null,
    inputProps: {
      disabled: false,
      onChange: () => {}
    }
  };

  toggleActive = () => {};

  render() {
    const { id, onClick, inputProps, value } = this.props;

    return (
      <a className="switch" id={id} onClick={onClick} value={value}>
        <input type="checkbox" className="slider padded" {...inputProps} />
        <span className="slider" />
      </a>
    );
  }
}
