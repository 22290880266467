﻿import React from "react";
import PropTypes from "prop-types";
import { Grid, Form, TextArea } from "semantic-ui-react";
import AlliedCheckBox from "../AlliedCheckBox";
import "./AddNotesModal.scss";

const AddNotesModal = props => {
  const { taskName, isExternalNote, handleExternalNote, note, handleTextChange } = props;

  return (
    <Grid columns={1}>
      <Grid.Column>
        <Form>
          <Grid>
            <Grid.Column width={8}>
              {" "}
              <b>Add a note to the {taskName}:</b>
            </Grid.Column>
            <Grid.Column width={6} floated="right">
              {" "}
              <AlliedCheckBox
                label="External Note"
                checked={isExternalNote}
                onChange={handleExternalNote}
              />
            </Grid.Column>
          </Grid>
          <TextArea
            name="note"
            placeholder="Enter notes here"
            value={note}
            onChange={handleTextChange}
          />
        </Form>
      </Grid.Column>
    </Grid>
  );
};

AddNotesModal.propTypes = {
  taskName: PropTypes.string,
  isExternalNote: PropTypes.bool,
  handleExternalNote: PropTypes.func,
  note: PropTypes.string,
  handleTextChange: PropTypes.func
};

AddNotesModal.defaultProps = {
  taskName: "",
  isExternalNote: false,
  handleExternalNote: null,
  note: "",
  handleTextChange: null
};

export default AddNotesModal;
