import React from "react";
import { Container, Header, Icon } from "semantic-ui-react";
import "./OspreyPageHeader.scss";
import PropTypes from "prop-types";

/**
 * A consistent header for pages
 */
class OspreyPageHeader extends React.PureComponent {
  render() {
    const { label, back, content, contentAlign, style, fluidContent } =
      this.props;
    return (
      <div className="ocl-top-header">
        <Container fluid>
          <Header as="h1" className="ocl-page-header">
            {label}
          </Header>
          {back !== null && (
            <div className="ocl-header-back" onClick={back.onClick}>
              <Icon name="arrow left" />
              Back to {back.label}
            </div>
          )}
          <div
            className="ocl-header-content"
            style={{
              ...style,
              float: contentAlign,
              width: fluidContent ? "100%" : "",
            }}
          >
            {content !== null && content}
          </div>
        </Container>
      </div>
    );
  }
}

OspreyPageHeader.propTypes = {
  /** Header information */
  label: PropTypes.arrayOf(PropTypes.string).isRequired,
  /** Generic object for inserting content */
  content: PropTypes.node,
  /** Determines whether contained content should be fluid */
  fluidContent: PropTypes.bool,
  /** Determines where the content should be aligned */
  contentAlign: PropTypes.oneOf(["left", "right"]),
  /** options for returning to previous content */
  back: PropTypes.shape({
    /** label for returning to previous content */
    label: PropTypes.arrayOf(PropTypes.string),
    /** onclick event for returning to previous content */
    onClick: PropTypes.func,
  }),
  style: PropTypes.shape(),
};

OspreyPageHeader.defaultProps = {
  content: null,
  back: null,
  contentAlign: "right",
  fluidContent: false,
  style: null,
};

export default OspreyPageHeader;
