import React from "react";
import PropTypes from "prop-types";

const SvgDocument = props => {
  const { color, ...rest } = props;
  return (
    <svg width={14} height={18} {...rest}>
      <defs>
        <path
          d="M15 8h-5V3H5v14h10V8zm-.414-2L12 3.414V6h2.586zm-2.172-5L17 5.586V19H3V1h9.414zM7 10h6v2H7v-2zm0 3h6v2H7v-2z"
          id="document_svg__a"
        />
      </defs>
      <g transform="translate(-3 -1)" fill="none" fillRule="evenodd">
        <mask id="document_svg__b" fill="#fff">
          <use xlinkHref="#document_svg__a" />
        </mask>
        <g mask="url(#document_svg__b)" fill={color}>
          <path d="M0 0h20v20H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgDocument.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgDocument;
