import React from "react";
import PropTypes from "prop-types";

const OptionallyDisplayed = props => {
  const { display, children } = props;
  return display === true ? <div>{children}</div> : null;
};

OptionallyDisplayed.propTypes = {
  display: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired
};

export default OptionallyDisplayed;
