import React from "react";
import PropTypes from "prop-types";

const SvgClockLarge = props => {
  const { color, ...rest } = props;
  return (
    <svg width={36} height={36} {...rest}>
      <defs>
        <path
          d="M19 17h6v2h-8V6h2v11zm-1 19C8.059 36 0 27.941 0 18S8.059 0 18 0s18 8.059 18 18-8.059 18-18 18zm0-2c8.837 0 16-7.163 16-16S26.837 2 18 2 2 9.163 2 18s7.163 16 16 16z"
          id="clockLarge_svg__a"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="clockLarge_svg__b" fill="#fff">
          <use xlinkHref="#clockLarge_svg__a" />
        </mask>
        <g mask="url(#clockLarge_svg__b)" fill={color}>
          <path d="M0 0h36v36H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgClockLarge.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgClockLarge;
