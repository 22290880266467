import React from "react";
import PropTypes from "prop-types";
import IconFactory, { AlliedColors } from "./icons";

const AlliedIcon = props => {
  const { icon } = props;
  return <React.Fragment>{IconFactory.buildInfo(icon, props)}</React.Fragment>;
};

AlliedIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
   color: PropTypes.string,
   name: PropTypes.string
};

AlliedIcon.defaultProps = {
   color: AlliedColors.default  
};

export default AlliedIcon;
