import React from "react";
import PropTypes from "prop-types";

const SvgTrash = props => {
  const { color, ...rest } = props;
  return (
    <svg width={18} height={18} {...rest}>
      <defs>
        <path
          d="M17 1h2v6h-2v12H3V7H1V1h16zm0 2H3v2h14V3zm-2 4H5v10h10V7zM7 8h2v8H7V8zm4 0h2v8h-2V8z"
          id="trash_svg__a"
        />
      </defs>
      <g transform="translate(-1 -1)" fill="none" fillRule="evenodd">
        <mask id="trash_svg__b" fill="#fff">
          <use xlinkHref="#trash_svg__a" />
        </mask>
        <g mask="url(#trash_svg__b)" fill={color}>
          <path d="M0 0h20v20H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgTrash.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgTrash;
