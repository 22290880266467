import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import * as Styled from "./styles";
import calendar, {
  isDate,
  isSameDay,
  isSameMonth,
  getDateISO,
  getNextMonth,
  getPreviousMonth,
  WEEK_DAYS,
  CALENDAR_MONTHS
} from "../helpers/calendar";
import AlliedIcon from "../../AlliedIcon/AlliedIcon";

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = { ...this.resolveStateFromProp(), today: new Date() };
  }

  componentDidMount() {
    const now = new Date();
    const tomorrow = new Date().setHours(0, 0, 0, 0) + 24 * 60 * 60 * 1000;
    const ms = tomorrow - now;

    this.dayTimeout = setTimeout(() => {
      this.setState({ today: new Date() }, this.clearDayTimeout);
    }, ms);
  }

  componentDidUpdate(prevProps) {
    const { date, onDateChanged } = this.props;
    const { date: prevDate } = prevProps;
    const dateMatch = date === prevDate || isSameDay(date, prevDate);

    if (!dateMatch)
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(this.resolveStateFromDate(date), () => {
        if (typeof onDateChanged === "function") onDateChanged(date);
      });
  }

  componentWillUnmount() {
    this.clearDayTimeout();
  }

  getCalendarDates = () => {
    const { current, month, year } = this.state;
    const calendarMonth = month || +current.getMonth() + 1;
    const calendarYear = year || current.getFullYear();

    return calendar(calendarMonth, calendarYear);
  };

  gotoDate = date => evt => {
    if (evt) evt.preventDefault();
    const { current } = this.state;
    const { onDateChanged } = this.props;

    if (!(current && isSameDay(date, current)))
      this.setState(this.resolveStateFromDate(date), () => {
        if (typeof onDateChanged === "function") onDateChanged(date);
      });
  };

  gotoPreviousMonth = () => {
    const { month, year } = this.state;
    this.setState(getPreviousMonth(month, year));
  };

  gotoNextMonth = () => {
    const { month, year } = this.state;
    this.setState(getNextMonth(month, year));
  };

  gotoPreviousYear = () => {
    const { year } = this.state;
    this.setState({ year: year - 1 });
  };

  gotoNextYear = () => {
    const { year } = this.state;
    this.setState({ year: year + 1 });
  };

  clearDayTimeout = () => {
    if (this.dayTimeout) clearTimeout(this.dayTimeout);
  };

  handlePrevious = evt => {
    if (evt) evt.preventDefault();
    const fn = evt.shiftKey ? this.gotoPreviousYear : this.gotoPreviousMonth;
    fn();
  };

  handleNext = evt => {
    if (evt) {
      evt.preventDefault();
    }
    const fn = evt.shiftKey ? this.gotoNextYear : this.gotoNextMonth;
    fn();
  };

  renderMonthAndYear = () => {
    const { month, year } = this.state;
    const monthname = Object.keys(CALENDAR_MONTHS)[Math.max(0, Math.min(month - 1, 11))];
    return (
      <Styled.CalendarHeader>
        <AlliedIcon
          icon="left"
          onClick={this.handlePrevious}
          title="Previous Month"
        />
        <Styled.CalendarMonth>
          {monthname} {year}
        </Styled.CalendarMonth>
        <AlliedIcon
          icon="right"
          onClick={this.handleNext}
          title="Next Month"
        />
      </Styled.CalendarHeader>
    );
  };

  renderDayLabel = (day, index) => {
    const daylabel = WEEK_DAYS[day];
    return (
      <Styled.CalendarDay key={daylabel} index={index}>
        {daylabel}
      </Styled.CalendarDay>
    );
  };

  renderCalendarDate = (date, index) => {
    const { isDateEnabled } = this.props;
    const { current, month, year, today } = this.state;

    let curDate = new Date(date.join("-"));

    const isToday = isSameDay(curDate, today);
    const isCurrent = current && isSameDay(curDate, current);
    const inMonth = month && year && isSameMonth(curDate, new Date([year, month, 1].join("-")));

    let isEnabled = true;
    if (typeof isDateEnabled === "function") {
      isEnabled = isDateEnabled(curDate);
    }

    const onClick = isEnabled ? this.gotoDate(curDate) : null;

    const props = { index, inMonth, onClick, title: curDate.toDateString() };

    let DateComponent;
    if (isEnabled) {
      if (isCurrent) {
        DateComponent = Styled.HighlightedCalendarDate;
      } else {
        if (isToday) {
          DateComponent = Styled.TodayCalendarDate;
        } else {
          DateComponent = Styled.CalendarDate;
        }
      }
    } else {
      DateComponent = Styled.DisabledDate;
    }

    return (
      <DateComponent key={getDateISO(curDate)} disabled={!isEnabled} {...props}>
        {curDate.getDate()}
      </DateComponent>
    );
  };

  resolveStateFromDate = date => {
    const { minDate } = this.props;
    const isDateObject = isDate(date);
    let curDate = isDateObject ? date : new Date();

    if (minDate && curDate < minDate) {
      curDate = minDate;
      date = minDate;
    }

    return {
      current: isDateObject ? date : null,
      month: +curDate.getMonth() + 1,
      year: curDate.getFullYear()
    };
  };

  resolveStateFromProp() {
    const { date } = this.props;
    return this.resolveStateFromDate(date);
  }

  render() {
    return (
      <Styled.CalendarContainer>
        {this.renderMonthAndYear()}

        <Styled.CalendarGrid>
          <Fragment>{Object.keys(WEEK_DAYS).map(this.renderDayLabel)}</Fragment>

          <Fragment>{this.getCalendarDates().map(this.renderCalendarDate)}</Fragment>
        </Styled.CalendarGrid>
      </Styled.CalendarContainer>
    );
  }
}

Calendar.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  onDateChanged: PropTypes.func.isRequired,
  isDateEnabled: PropTypes.func
};

Calendar.defaultProps = {
  isDateEnabled: null
}

export default Calendar;
