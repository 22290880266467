import React from "react";
import PropTypes from "prop-types";

const SvgUpload = props => {
  const { color, ...rest } = props;
  return (
    <svg width={18} height={18} {...rest}>
      <defs>
        <path
          d="M9 4.312L6.114 6.556 4.886 4.978 10 1l5.114 3.978-1.228 1.578L11 4.312v9.955H9V4.312zM3 17h14v-4h2v6H1v-6h2v4z"
          id="upload_svg__a"
        />
      </defs>
      <g transform="translate(-1 -1)" fill="none" fillRule="evenodd">
        <mask id="upload_svg__b" fill="#fff">
          <use xlinkHref="#upload_svg__a" />
        </mask>
        <g mask="url(#upload_svg__b)" fill={color}>
          <path d="M0 0h20v20H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgUpload.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgUpload;
