import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Header, Modal, Popup } from "semantic-ui-react";
import AlliedButton from "../AlliedButton/AlliedButton";
import AlliedIcon from "../AlliedIcon/AlliedIcon";
import { AlliedColors } from "../AlliedIcon/icons";
import "./AlliedModal.scss";

const AlliedModal = props => {
  const iconClass = classNames({
    [`icon-padded`]: true
  });

  function colorSelector() {
    switch (props.type) {
      case "success":
        return AlliedColors.success;
      case "caution":
        return AlliedColors.caution;
      case "error":
        return AlliedColors.error;
      default:
        return AlliedColors.default;
    }
  }

  const {
    open,
    onClose,
    icon,
    header,
    subtitle,
    message,
    singleButton,
    cancelText,
    confirmText,
    onCancel,
    onSubmit,
    size,
    confirmDisabled,
    confirmDisabledReason
  } = props;
  return (
    <Modal open={open} id="allied-modal" onClose={onClose} size={size}>
      <Modal.Content className="allied-modal_content">
        <AlliedIcon icon={icon} color={colorSelector()} className={iconClass} />
        <Modal.Description className="full-width">
          <Header>{header}</Header>
          {typeof subtitle === "string" || subtitle instanceof String ? (
            <p>{subtitle}</p>
          ) : (
            subtitle
          )}
          {message && <p>{message}</p>}
        </Modal.Description>
        <div className="button-group">
          {!singleButton && (
            <AlliedButton color="green" inverted onClick={onCancel}>
              {cancelText}
            </AlliedButton>
          )}
          <Popup
            className="disabled-popper"
            position="top center"
            content={confirmDisabledReason}
            disabled={!confirmDisabled}
            trigger={
              <div>
                <AlliedButton color="green" onClick={onSubmit} disabled={confirmDisabled}>
                  {confirmText}
                </AlliedButton>
              </div>
            }
          ></Popup>
        </div>
      </Modal.Content>
    </Modal>
  );
};

AlliedModal.propTypes = {
  open: PropTypes.bool,
  type: PropTypes.string,
  icon: PropTypes.string,
  singleButton: PropTypes.bool,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  message: PropTypes.string,
  size: PropTypes.string,
  confirmDisabled: PropTypes.bool,
  confirmDisabledReason: PropTypes.string
};

AlliedModal.defaultProps = {
  open: false,
  type: "warning",
  icon: "warning",
  singleButton: false,
  cancelText: "Cancel",
  confirmText: "Submit",
  message: null,
  size: "tiny",
  confirmDisabled: false,
  confirmDisabledReason: "This button is disabled."
};

export default AlliedModal;
