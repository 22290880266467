import React from "react";
import PropTypes from "prop-types";

const SvgCpiBilling = props => {
  const { color, ...rest } = props;
  return (
    <svg width={14} height={18} {...rest}>
      <defs>
        <path
          d="M5 5h10V3H5v2zm0 2v10h10V7H5zm0-6h12v18H3V1h2zm1 7h2v2H6V8zm0 3h2v2H6v-2zm0 3h2v2H6v-2zm3-6h2v2H9V8zm0 3h2v2H9v-2zm0 3h2v2H9v-2zm3-6h2v2h-2V8zm0 3h2v5h-2v-5z"
          id="cpi-billing_svg__a"
        />
      </defs>
      <g transform="translate(-3 -1)" fill="none" fillRule="evenodd">
        <mask id="cpi-billing_svg__b" fill="#fff">
          <use xlinkHref="#cpi-billing_svg__a" />
        </mask>
        <g mask="url(#cpi-billing_svg__b)" fill={color}>
          <path d="M0 0h20v20H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgCpiBilling.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgCpiBilling;
