import React from "react";
import PropTypes from "prop-types";

const SvgEllipsis = props => {
  const { color, ...rest } = props;
  return (
    <svg width={26} height={6} {...rest}>
      <defs>
        <path
          d="M13 26a3 3 0 110-6 3 3 0 010 6zm10 0a3 3 0 110-6 3 3 0 010 6zm10 0a3 3 0 110-6 3 3 0 010 6z"
          id="ellipsis_svg__a"
        />
      </defs>
      <g transform="translate(-10 -20)" fill="none" fillRule="evenodd">
        <mask id="ellipsis_svg__b" fill="#fff">
          <use xlinkHref="#ellipsis_svg__a" />
        </mask>
        <g mask="url(#ellipsis_svg__b)" fill={color}>
          <path d="M0 0h46v46H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgEllipsis.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgEllipsis;
