import React, { Component } from "react";
import { Dropdown, Form } from "semantic-ui-react";
import PropTypes from "prop-types";
import { orderBy, map } from "lodash";
import axios from "axios";

class RefCodeSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
    };
  }

  componentDidMount() {
    this.fetchRefCodes();
  }

  componentDidUpdate(prevProps) {
    const { refDomain, defaultOptions, valueType, sorted, removeKeys } =
      this.props;
    const {
      refDomain: prevRefDomain,
      defaultOptions: prevDefaultOptions,
      valueType: prevValueType,
      sorted: prevSorted,
      removeKeys: prevRemoveKeys,
    } = prevProps;

    if (
      refDomain !== prevRefDomain ||
      defaultOptions !== prevDefaultOptions ||
      valueType !== prevValueType ||
      sorted !== prevSorted ||
      removeKeys !== prevRemoveKeys
    ) {
      this.fetchRefCodes();
    }
  }

  fetchRefCodes = async () => {
    const { refDomain, defaultOptions, valueType, sorted, removeKeys } =
      this.props;
    const { data } = await axios.get(
      `/api/account/GetRefCodes?domain=${refDomain}`
    );

    const options =
      defaultOptions !== null && defaultOptions.length ? defaultOptions : [];

    map(data, (refCode) => {
      if (!removeKeys.includes(refCode.code)) {
        options.push({
          key: refCode.code,
          text: refCode.meaning,
          value: refCode[valueType],
        });
      }
    });

    this.setState({
      options: sorted ? orderBy(options, "text", sorted) : options,
    });
  };

  render() {
    const { options } = this.state;
    const {
      refDomain,
      sorted,
      defaultOptions,
      valueType,
      type,
      removeKeys,
      ...rest
    } = this.props;

    return type === "standard" ? (
      <Dropdown
        {...rest}
        options={options}
        loading={!options.length}
        selection
      />
    ) : (
      <Form.Select {...rest} options={options} loading={!options.length} />
    );
  }
}

RefCodeSelect.propTypes = {
  refDomain: PropTypes.string.isRequired,
  defaultOptions: PropTypes.arrayOf(PropTypes.object),
  sorted: PropTypes.oneOf(["asc", "desc"]),
  valueType: PropTypes.oneOf(["code", "meaning"]),
  type: PropTypes.oneOf(["standard", "form"]),
  removeKeys: PropTypes.arrayOf(PropTypes.string),
};

RefCodeSelect.defaultProps = {
  valueType: "code",
  defaultOptions: null,
  type: "standard",
  sorted: null,
  removeKeys: [],
};

export default RefCodeSelect;
