import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Header } from "semantic-ui-react";

import { SYSTEM_ACTIVITY_ROUTE } from "../../../constants";

import "./ViewChangeHistory.scss";

class ViewChangeHistory extends Component {
  viewHistory = () => {
    const { id, history, type } = this.props;
    if (id > 0 && type.length > 0) {
      history.push({
        pathname: SYSTEM_ACTIVITY_ROUTE,
        search: `id=${id}&type=${type}`,
      });
    }
  };

  render() {
    const { className, ...rest } = this.props;

    return (
      <Header
        as="h3"
        color="blue"
        className={`vch-link ${className}`}
        onClick={this.viewHistory}
        {...rest}
      >
        View Change History
      </Header>
    );
  }
}

ViewChangeHistory.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
};

ViewChangeHistory.defaultProps = {
  className: "",
};

export default withRouter(ViewChangeHistory);
