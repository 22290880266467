import React from "react";
import PropTypes from "prop-types";

const SvgCollapseMedium = props => {
  const { color, ...rest } = props;
  return (
    <svg width={22} height={22} {...rest}>
      <defs>
        <path d="M1 1h22v22H1V1zm6 12h10v-2H7v2z" id="collapseMedium_svg__a" />
      </defs>
      <g transform="translate(-1 -1)" fill="none" fillRule="evenodd">
        <mask id="collapseMedium_svg__b" fill="#fff">
          <use xlinkHref="#collapseMedium_svg__a" />
        </mask>
        <g mask="url(#collapseMedium_svg__b)" fill={color}>
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgCollapseMedium.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgCollapseMedium;
