import React from "react";
import PropTypes from "prop-types";

const SvgEditing = props => {
  const { color, ...rest } = props;
  return (
    <svg width={12} height={2} {...rest}>
      <defs>
        <path id="editing_svg__a" d="M0 0h12v2H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="editing_svg__b" fill="#fff">
          <use xlinkHref="#editing_svg__a" />
        </mask>
        <g mask="url(#editing_svg__b)" fill={color}>
          <path d="M-4-9h20v20H-4z" />
        </g>
      </g>
    </svg>
  );
};

SvgEditing.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgEditing;
