import React from "react";
import { Button, Card } from "semantic-ui-react";
import PropTypes from "prop-types";
import axios from "axios";
import { some, isEmpty, isNil } from "lodash";

class CardMenuOption extends React.Component {
  componentDidMount() {
    const { endpoint } = this.props;
    if (endpoint) {
      this.fetchData();
    }
  }

  /**
   * Called on clicking the card button
   * @param {Object}: event
   * @param {Object}: data
   */
  handleButtonClick = (event, data) => {
    const { onClick } = this.props;
    if (onClick) {
      onClick(event, data);
    }
  };

  fetchData = async () => {
    const {
      name,
      endpoint: { url, callback, params }
    } = this.props;

    if (!some(params, isNil || isEmpty)) {
      const { data } = await axios.get(`${url}?${this.queryString(params)}`);
      if (name) callback(name, data);
    }
  };

  queryString = data => {
    return Object.keys(data)
      .map(key => `${key}=${encodeURIComponent(data[key])}`)
      .join("&");
  };

  render() {
    const { header, subheader, description, buttonLabel, buttonName, buttonEnabled } = this.props;
    return (
      <Card>
        <Card.Content>
          <Card.Header>{header}</Card.Header>
          <Card.Meta>{subheader}</Card.Meta>
          <Card.Description>{description}</Card.Description>
        </Card.Content>
        <Card.Content extra>
          <div className="ui two buttons">
            <Button
              disabled={buttonEnabled === false}
              basic
              color="green"
              onClick={this.handleButtonClick}
              className="ocs-card-button"
              name={buttonName}
            >
              {buttonLabel}
            </Button>
          </div>
        </Card.Content>
      </Card>
    );
  }
}

export default CardMenuOption;

CardMenuOption.propTypes = {
  /** Header for the card */
  header: PropTypes.string,
  /** Subheader for the card */
  subheader: PropTypes.string,
  /** Description for the card */
  description: PropTypes.string,
  /** Label for the button on card */
  buttonLabel: PropTypes.string,
  /** Click event for button */
  onClick: PropTypes.func,
  /** Name for referencing click event */
  buttonName: PropTypes.string,
  /** Pass false to disable the button. Defaults to true */
  buttonEnabled: PropTypes.bool,
  /** API Endpoint for fetching data */
  endpoint: PropTypes.shape({
    url: PropTypes.string,
    params: PropTypes.object,
    callback: PropTypes.func
  }),
  /** Name for the card */
  name: PropTypes.string
};

CardMenuOption.defaultProps = {
  header: "",
  subheader: "",
  description: "",
  buttonLabel: "",
  onClick: () => null,
  buttonName: "",
  endpoint: null,
  name: null,
  buttonEnabled: true
};
