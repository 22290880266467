import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./AlliedButtonGroup.scss";
import classNames from "classnames";

class AlliedButtonGroup extends PureComponent {
  render() {
    const { flex, className, children } = this.props;
    const groupClass = classNames({
      "cp-btn-group": true,
      "cp-btn-group-flex": flex,
      [`${className}`]: true
    });

    return <div className={groupClass}>{children}</div>;
  }
}

AlliedButtonGroup.propTypes = {
  children: PropTypes.node.isRequired,
  flex: PropTypes.bool,
  className: PropTypes.string
};

AlliedButtonGroup.defaultProps = {
  flex: false,
  className: ""
};

export default AlliedButtonGroup;
