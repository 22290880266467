import React from "react";
import PropTypes from "prop-types";

const SvgVehicle = props => {
  const { color, ...rest } = props;
  return (
    <svg width={46} height={23} {...rest}>
      <defs>
        <path
          d="M18.084 27h12.401a5.001 5.001 0 019.168 0h2.881l1.381-2.072-2.142-4.284L39.718 19H21.5v-2h3v-4h-6.586l-4 4H16.5v2H5.22l-1.333 4H5.75v2h-4 .75l.447.106L6.737 27h2.179a5.001 5.001 0 019.168 0zm.416 2a5 5 0 01-10 0h-2l-.447-.106L2.263 27H1l-1-1v-2l1-1h.78l1.771-5.316L4.5 17h6.586l5.707-5.707L17.5 11h8l.678.265L32.391 17h7.678l.625.22 2.5 2 .27.333 2.5 5-.063 1.002-2 3-.832.445h-3a5 5 0 01-10 0H18.5zm8-14.716V17h2.942L26.5 14.284zM13.5 32a3 3 0 100-6 3 3 0 000 6zm21.57 0a3 3 0 100-6 3 3 0 000 6z"
          id="vehicle_svg__a"
        />
      </defs>
      <g transform="translate(0 -11)" fill="none" fillRule="evenodd">
        <mask id="vehicle_svg__b" fill="#fff">
          <use xlinkHref="#vehicle_svg__a" />
        </mask>
        <g mask="url(#vehicle_svg__b)" fill={color}>
          <path d="M0 0h46v46H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgVehicle.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgVehicle;
