import React from "react";
import PropTypes from "prop-types";

const SvgRecovery = props => {
  const { color, ...rest } = props;
  return (
    <svg width={18} height={18} {...rest}>
      <defs>
        <path
          d="M18 16.885h1v2H1v-2h1v-9h2v9h2v-9h2v9h4v-9h2v9h2v-9h2v9zM9.966 1L19 6l-.466 1.885H1.507L1 6.023l7.993-5L9.966 1zM4.672 5.885h10.28L9.528 3.029 4.672 5.885z"
          id="recovery_svg__a"
        />
      </defs>
      <g transform="translate(-1 -1)" fill="none" fillRule="evenodd">
        <mask id="recovery_svg__b" fill="#fff">
          <use xlinkHref="#recovery_svg__a" />
        </mask>
        <g mask="url(#recovery_svg__b)" fill={color}>
          <path d="M0 0h20v20H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgRecovery.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgRecovery;
