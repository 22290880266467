﻿/* eslint-disable react/no-array-index-key */
import React from "react";
import { Button, Form, Grid, Header, Icon, Popup, Segment } from "semantic-ui-react";
import validator from "validator";
import PropTypes from "prop-types";
import { validatePhoneNumber } from "../../helperFunctions/phoneFormatter";
import AlliedIcon from "../AlliedIcon/AlliedIcon";
import AlliedButton from "../AlliedButton/AlliedButton";
import { AlliedColors } from "../..";
import "./AddEditDeleteTable.scss";

/**
 * Reusable Table which features an add new button, as well as the ability to update and delete existing data
 */
class AddEditDeleteTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      table: {
        title: props.title,
        headers: props.headers,
        requiredFields: props.headers
          .filter(header => header.options.required === true)
          .map(header => header.name),
        data: props.data,
        relatedData: props.relatedData,
        addDataCallback: props.addDataCallback,
        deleteDataCallback: props.deleteDataCallback,
        cellChangeCallback: props.cellChangeCallback,
        saveRowCallback: props.saveRowCallback,
        saveTableCallback: props.saveTableCallback,
        newData: null
      },
      isEditingRow: props.isEditingRow,
      tableDirty: false,
      newRowIndex: -1,
      existingData: null,
      renderingPage: props.renderingPage
    };
  }

  renderHeaders = (header, index) => {
    return index === 0 ? null : (
      <Grid.Column className="tabular-grid-row header-row" onClick={() => {}}>
        <Grid raised columns={1}>
          <Grid.Row verticalAlign="left">
            <Grid.Column key={index} textAlign="center">
              <b>{header}</b>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
    );
  };

  errorField = error => {
    return (
      error && (
        <div className="error-div">
          <AlliedIcon icon="error" color={AlliedColors.error} /> {error}
        </div>
      )
    );
  };

  validateCell = (value, cellIndex) => {
    const {
      table: { headers }
    } = this.state;
    const header = headers[cellIndex].name;

    if (header.toLowerCase().includes("first name") || header.toLowerCase().includes("last name")) {
      return !(value === header || value.length === 0);
    }
    if (header.toLowerCase().includes("phone")) {
      return validatePhoneNumber(value);
    }
    if (header.toLowerCase().includes("email")) {
      return !(value === `${header}...` || value.length === 0 || !validator.isEmail(value));
    }
    if (header.toLowerCase().includes("cc list")) {
      return value.length > 0 ? value.split(";").every(this.checkEmail) : true;
    }

    return true;
  };

  checkEmail = email => {
    return validator.isEmail(email.trim());
  };

  validateRow = tableRow => {
    const {
      table: { headers }
    } = this.state;

    return headers.every(header => {
      return header.name === "ID" ? true : this.validate(tableRow, header.name);
    });
  };

  validate = (tableRow, header) => {
    if (header.toLowerCase().includes("first name") || header.toLowerCase().includes("last name")) {
      if (!tableRow) {
        return false;
      }
      return !(tableRow[header] === header || tableRow[header].length === 0);
    }
    if (header.toLowerCase().includes("phone")) {
      return validatePhoneNumber(tableRow[header]);
    }
    if (header.toLowerCase().includes("email")) {
      return !(
        tableRow[header] === `${header}...` ||
        tableRow[header].length === 0 ||
        !validator.isEmail(tableRow[header])
      );
    }
    if (header.toLowerCase().includes("cc list")) {
      return tableRow[header].length > 0
        ? tableRow[header].split(";").every(this.checkEmail)
        : true;
    }
    return true;
  };

  renderRow = (data, rowIndex) => {
    const {
      newRowIndex,
      isEditingRow,
      tableDirty,
      table: { relatedData, deleteDataCallback }
    } = this.state;
    const dataCells = [];
    let deleteDisabled = false;

    Object.keys(data).forEach(e => {
      if (e !== "markForRemoval") {
        dataCells.push(data[e]);
      }
    });

    Object.values(relatedData).forEach(val => {
      if (val === data.ID) {
        deleteDisabled = true;
      }
    });

    if (rowIndex !== newRowIndex || !isEditingRow) {
      return (
        <Grid.Row verticalAlign="left" className="table-row" key={rowIndex}>
          {dataCells.map((value, cellIndex) => {
            return cellIndex === 0 ? null : (
              <Grid.Column key={cellIndex} textAlign="center">
                <div>
                  <p className="text-overflow">{value}</p>
                </div>
              </Grid.Column>
            );
          })}
          <Grid.Column width={1} verticalAlign="middle" floated="middle">
            <Button
              icon
              size="small"
              floated="middle"
              compact
              basic
              disabled={isEditingRow || tableDirty}
              onClick={() => {
                if (!isEditingRow) {
                  this.setState(prevState => ({
                    ...prevState,
                    tableDirty: true,
                    isEditingRow: true,
                    newRowIndex: rowIndex,
                    rowHasErrors: !this.validateRow(prevState.table.data.tableRows[rowIndex]),
                    existingData: prevState.table.data.tableRows[rowIndex]
                  }));
                }
              }}
            >
              <Icon name="edit" color="blue" />
            </Button>
          </Grid.Column>

          <Grid.Column width={1} verticalAlign="middle" floated="middle">
            <Popup
              content="This cannot be deleted because it is in use"
              disabled={!deleteDisabled}
              position="top center"
              trigger={
                <div>
                  <Button
                    icon
                    size="small"
                    disabled={isEditingRow || tableDirty || deleteDisabled}
                    floated="middle"
                    compact
                    basic
                    color="red"
                    onClick={async () => {
                      if (!isEditingRow) {
                        await deleteDataCallback(rowIndex);
                        this.setState(prevState => ({
                          ...prevState,
                          newRowIndex: -1,
                          isEditingRow: false,
                          isDirty: false,
                          rowHasErrors: false,
                          table: {
                            ...prevState.table,
                            newData: null
                          }
                        }));
                      }
                    }}
                  >
                    <Icon name="trash alternate" color="red" />
                  </Button>
                </div>
              }
            />
          </Grid.Column>
        </Grid.Row>
      );
    }
    return (
      <Grid.Row className="editing-row" verticalAlign="center" key={rowIndex}>
        {dataCells.map((value, cellIndex) => {
          const {
            table: { cellChangeCallback, headers, newData, requiredFields },
            renderingPage
          } = this.state;
          const newCell = newData !== null ? newData[headers[cellIndex].name] : value;
          const isValid = this.validateCell(newCell, cellIndex);
          const isRequired = requiredFields.includes(headers[cellIndex].name);
          const currentData = {};
          headers.forEach((header, index) => {
            currentData[header.name] = dataCells[index];
          });
          // eslint-disable-next-line no-nested-ternary
          return cellIndex === 0 ? null : isValid ? (
            <Grid.Column key={cellIndex} textAlign="center" className="tabular-grid-row">
              <Form autoComplete="off">
                <Form.Input
                  size="small"
                  fluid
                  name={`${rowIndex}:${cellIndex} editCellInput`}
                  id={`${renderingPage}__AddEditDeleteTable--${rowIndex}:${cellIndex} editCellInput`}
                  onChange={event => {
                    const existingData = newData !== null ? newData : currentData;
                    const newDataLocal = cellChangeCallback(
                      event.target.value,
                      cellIndex,
                      existingData
                    );
                    const isRowValid = this.validateRow(newDataLocal);
                    this.setState(prevState => ({
                      ...prevState,
                      rowHasErrors: isRowValid !== true,
                      existingData: newData,
                      table: {
                        ...prevState.table,
                        newData: newDataLocal
                      }
                    }));
                  }}
                  type="text"
                  placeholder={
                    isRequired ? `${headers[cellIndex].name} (required)` : headers[cellIndex].name
                  }
                  value={newData !== null ? newCell : value}
                />
              </Form>
            </Grid.Column>
          ) : (
            <Grid.Column key={cellIndex} textAlign="center" className="tabular-grid-row">
              <Form autoComplete="off">
                <Form.Input
                  size="small"
                  fluid
                  name={`${rowIndex}:${cellIndex} editCellInput`}
                  id={`${renderingPage}__AddEditDeleteTable--${rowIndex}:${cellIndex} editCellInput`}
                  onChange={event => {
                    const existingData = newData !== null ? newData : currentData;
                    const newDataLocal = cellChangeCallback(
                      event.target.value,
                      cellIndex,
                      existingData
                    );
                    const isRowValid = this.validateRow(newData);

                    this.setState(prevState => ({
                      ...prevState,
                      rowHasErrors: isRowValid !== true,
                      existingData: newData,
                      table: {
                        ...prevState.table,
                        newData: newDataLocal
                      }
                    }));
                  }}
                  type="text"
                  placeholder={
                    isRequired ? `${headers[cellIndex].name} (required)` : headers[cellIndex].name
                  }
                  value={newData !== null ? newCell : value}
                  error
                />
              </Form>
            </Grid.Column>
          );
        })}
        <Grid.Column width={1} className="tabular-grid-row">
          {null}
        </Grid.Column>
        <Grid.Column width={1} verticalAlign="middle" floated="middle">
          <Button
            icon
            floated="middle"
            disabled={tableDirty}
            size="small"
            basic
            color="red"
            onClick={async () => {
              await deleteDataCallback(rowIndex);
              this.setState(prevState => ({
                ...prevState,
                newRowIndex: -1,
                isEditingRow: false,
                isDirty: false,
                rowHasErrors: false,
                tableDirty: true,
                table: {
                  ...prevState.table,
                  newData: null
                }
              }));
            }}
          >
            <Icon name="trash alternate" color="red" />
          </Button>
        </Grid.Column>
      </Grid.Row>
    );
  };

  renderTable = (title, headers, rows) => {
    const {
      tableDirty,
      rowHasErrors,
      newRowIndex,
      existingData,
      table: { addDataCallback, saveRowCallback, saveTableCallback },
      renderingPage
    } = this.state;
    const { saveDisabled } = this.props;
    return (
      <Segment.Group raised>
        <Segment>
          <Grid raised>
            <Grid.Row columns={2}>
              <Grid.Column className="tabular-grid-row">
                <Header as="h3">{title}</Header>
              </Grid.Column>
              <Grid.Column textAlign="right" className="tabular-grid-row">
                <div>
                  {!tableDirty ? (
                    <AlliedButton
                      id={`${renderingPage}__AddEditDeleteTable_AlliedButton--new-client-btn`}
                      color="green"
                      onClick={async () => {
                        const newRowIndexLocal = rows.length;
                        const data = await addDataCallback(newRowIndexLocal);
                        this.setState(prevState => ({
                          ...prevState,
                          table: {
                            ...prevState.table,
                            data
                          },
                          newRowIndex: newRowIndexLocal,
                          existingData: rows[newRowIndexLocal],
                          isEditingRow: true,
                          tableDirty: true,
                          rowHasErrors: true
                        }));
                      }}
                    >
                      Add New
                    </AlliedButton>
                  ) : (
                    <AlliedButton
                      disabled={rowHasErrors || saveDisabled}
                      id={`${renderingPage}__AddEditDeleteTable_AlliedButton--new-client-btn`}
                      color="blue"
                      onClick={async () => {
                        let newRows = rows;
                        if (newRowIndex > -1) {
                          newRows = saveRowCallback(existingData, newRowIndex);
                        }

                        await saveTableCallback(newRows);

                        this.setState(prevState => ({
                          ...prevState,
                          newRowIndex: -1,
                          tableDirty: false,
                          isEditingRow: false,
                          isDirty: false,
                          rowHasErrors: false,
                          table: {
                            ...prevState.table,
                            newData: null
                          }
                        }));
                      }}
                    >
                      Save Table
                    </AlliedButton>
                  )}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment>
          <React.Fragment>
            <Grid columns="equal">
              <Grid.Row>
                {headers.map((header, index) => this.renderHeaders(header.name, index))}
                <Grid.Column width={1} className="tabular-grid-row" />
                <Grid.Column width={1} className="tabular-grid-row" />
              </Grid.Row>
              {rows.map((row, index) => this.renderRow(row, index))}
            </Grid>
          </React.Fragment>
        </Segment>
      </Segment.Group>
    );
  };

  render() {
    const {
      table: { title, headers, data }
    } = this.state;

    const rows = data.tableRows.filter(d => d.markForRemoval === false);
    return (
      <React.Fragment>
        <Grid.Column width={16}>{this.renderTable(title, headers, rows)}</Grid.Column>
      </React.Fragment>
    );
  }
}

AddEditDeleteTable.propTypes = {
  title: PropTypes.string,
  headers: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.shape(PropTypes.any).isRequired,
  relatedData: PropTypes.shape(PropTypes.any).isRequired,
  addDataCallback: PropTypes.func.isRequired,
  cellChangeCallback: PropTypes.func.isRequired,
  saveRowCallback: PropTypes.func.isRequired,
  deleteDataCallback: PropTypes.func.isRequired,
  saveTableCallback: PropTypes.func.isRequired,
  isEditingRow: PropTypes.bool,
  saveDisabled: PropTypes.bool,
  renderingPage: PropTypes.string
};

AddEditDeleteTable.defaultProps = {
  title: "Table Title",
  headers: ["H1", "H2", "H3"],
  isEditingRow: false,
  saveDisabled: false,
  renderingPage: ""
};

export default AddEditDeleteTable;
