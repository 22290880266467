import React from "react";
import PropTypes from "prop-types";

const SvgHouse = props => {
  const { color, ...rest } = props;
  return (
    <svg width={44} height={40} {...rest}>
      <defs>
        <path
          d="M40.65 22.76v20h-15v-15h-6v15h-15v-20h-3L1 21 22 3h1.302l21 18-.651 1.76h-3zm-2 0h-3.59v-2h5.888L22.65 5.075 4.354 20.76h5.888v2H6.65v18h11v-15h10v15h11v-18zm-8 9h2v2h-2v-2zm0-3h2v2h-2v-2zm3 3h2v2h-2v-2zm0-3h2v2h-2v-2zm-24 3h2v2h-2v-2zm0-3h2v2h-2v-2zm3 3h2v2h-2v-2zm0-3h2v2h-2v-2z"
          id="house_svg__a"
        />
      </defs>
      <g transform="translate(-1 -3)" fill="none" fillRule="evenodd">
        <mask id="house_svg__b" fill="#fff">
          <use xlinkHref="#house_svg__a" />
        </mask>
        <g mask="url(#house_svg__b)" fill={color}>
          <path d="M0 0h46v46H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgHouse.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgHouse;
