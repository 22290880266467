import React from "react";
import PropTypes from "prop-types";

const SvgLeftArrow = props => {
  const { color, ...rest } = props;
  return (
    <svg width={18} height={10} {...rest}>
      <defs>
        <path
          d="M4.331 9H19v2H4.293l2.248 2.961L4.964 15 1 10.062 4.964 5 6.57 6.19 4.331 9z"
          id="left-arrow_svg__a"
        />
      </defs>
      <g transform="translate(-1 -5)" fill="none" fillRule="evenodd">
        <mask id="left-arrow_svg__b" fill="#fff">
          <use xlinkHref="#left-arrow_svg__a" />
        </mask>
        <g mask="url(#left-arrow_svg__b)" fill={color}>
          <path d="M0 0h20v20H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgLeftArrow.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgLeftArrow;
