import React from "react";
import PropTypes from "prop-types";

const SvgCaution = props => {
  const { color, ...rest } = props;
  return (
    <svg width={18} height={18} {...rest}>
      <defs>
        <path
          d="M1 10c0-4.964 4.036-9 9-9s9 4.036 9 9c0 4.963-4.037 9-9 9s-9-4.037-9-9zm2 0c0 3.859 3.141 7 7 7s7-3.141 7-7c0-3.86-3.14-7-7-7s-7 3.14-7 7zm6 3h2v2H9v-2zm0-8h2v7H9V5z"
          id="caution_svg__a"
        />
      </defs>
      <g transform="translate(-1 -1)" fill="none" fillRule="evenodd">
        <mask id="caution_svg__b" fill="#fff">
          <use xlinkHref="#caution_svg__a" />
        </mask>
        <g mask="url(#caution_svg__b)" fill={color}>
          <path d="M0 0h20v20H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgCaution.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgCaution;
