import React from "react";
import PropTypes from "prop-types";

const SvgBuilding = props => {
  const { color, ...rest } = props;
  return (
    <svg width={46} height={46} {...rest}>
      <defs>
        <path
          d="M44 0h2v46H0V12h22V0h22zm0 2H24v42h20V2zM2 44h20V14H2v30zM38 4h4v4h-4V4zm0 6h4v4h-4v-4zm0 6h4v4h-4v-4zm0 6h4v4h-4v-4zm-22-6h4v4h-4v-4zm0 6h4v4h-4v-4zM32 4h4v4h-4V4zm0 6h4v4h-4v-4zm0 6h4v4h-4v-4zm0 6h4v4h-4v-4zm-22-6h4v4h-4v-4zm0 6h4v4h-4v-4zM26 4h4v4h-4V4zm0 6h4v4h-4v-4zm0 6h4v4h-4v-4zm0 6h4v4h-4v-4zM4 16h4v4H4v-4zm0 6h4v4H4v-4z"
          id="building_svg__a"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="building_svg__b" fill="#fff">
          <use xlinkHref="#building_svg__a" />
        </mask>
        <g mask="url(#building_svg__b)" fill={color}>
          <path d="M0 0h46v46H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgBuilding.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgBuilding;
