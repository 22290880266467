import React from "react";
import PropTypes from "prop-types";

const SvgBell = props => {
  const { color, ...rest } = props;
  return (
    <svg width={21} height={22} {...rest}>
      <defs>
        <path
          d="M4.374 14.74V12a7.998 7.998 0 014.124-7h-.124a4 4 0 018 0h-.124a7.998 7.998 0 014.124 7v2.536l2.384 4.496-.884 1.468h-6.541a3 3 0 01-5.917 0H2.874L2 19.014l2.374-4.273zM5.963 16l-1.39 2.5h15.639L18.886 16H5.963zm5.545 4.5a1 1 0 001.732 0h-1.732zm-.973-16.288a8.023 8.023 0 013.678 0 2 2 0 00-3.678 0zM6.375 12v2h12v-2a6 6 0 10-12 0z"
          id="bell_svg__a"
        />
      </defs>
      <g transform="translate(-2 -1)" fill="none" fillRule="evenodd">
        <mask id="bell_svg__b" fill="#fff">
          <use xlinkHref="#bell_svg__a" />
        </mask>
        <g mask="url(#bell_svg__b)" fill={color}>
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgBell.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgBell;
