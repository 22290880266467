import styled from "styled-components";
import { Label, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { Input } from "semantic-ui-react";

export const DatePickerContainer = styled.div`
  position: relative;
`;

export const DatePickerLabel = styled(Label)`
  height: 20.4px;
  width: 100.9px;
  color: #031c2c;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`;

export const DatePickerInput = styled(Input)`
  height: 50px;
  width: ${props => (props.fluid ? "inherit" : "150px")};
  margin-right: 0px !important;

  ::placeholder {
    height: 21px;
    width: 150px;
    color: #031c2c;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
  }
`;

export const DatePickerDropdown = styled(Dropdown)``;

export const DatePickerDropdownToggle = styled(DropdownToggle)`
  display: none;
`;

export const DatePickerDropdownMenu = styled(DropdownMenu)`
  position: absolute;
  top: ${props => (props.position === "top" ? "auto !important" : "0px")};
  bottom: ${props => (props.position === "top" ? "100% !important" : "")};
  margin-bottom: ${props => (props.position === "top" ? "50px !important" : "0px")};
  left: 0;
  width: 227px;
  border: none;
  transform: none !important;
  box-shadow: 0 2px 8px 2px rgba(0, 89, 148, 0.06);
`;
