import React from "react";
import PropTypes from "prop-types";

const SvgCar = props => {
  const { color, ...rest } = props;
  return (
    <svg width={18} height={18} {...rest}>
      <defs>
        <path
          d="M17 17v2h-3v-2H6v2H3v-2H2l-1-1V8l1-1h.28l2-6h10.887l2.5 6H18l1 1v8l-1 1h-1zM4.387 7H15.5l-1.667-4H5.721L4.387 7zM3 9v6h14V9H3zm2 2h2v2H5v-2zm8 0h2v2h-2v-2zm-5 0h4v2H8v-2z"
          id="car_svg__a"
        />
      </defs>
      <g transform="translate(-1 -1)" fill="none" fillRule="evenodd">
        <mask id="car_svg__b" fill="#fff">
          <use xlinkHref="#car_svg__a" />
        </mask>
        <g mask="url(#car_svg__b)" fill={color}>
          <path d="M0 0h20v20H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgCar.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgCar;
