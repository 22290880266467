import React from "react";
import PropTypes from "prop-types";

const SvgSuccessLarge = props => {
  const { color, ...rest } = props;
  return (
    <svg width={36} height={36} {...rest}>
      <defs>
        <path
          d="M18 36C8.059 36 0 27.941 0 18S8.059 0 18 0s18 8.059 18 18-8.059 18-18 18zm0-2c8.837 0 16-7.163 16-16S26.837 2 18 2 2 9.163 2 18s7.163 16 16 16zm6.22-25l1.78.912-8.967 16.893-1.771.017L10 18.412l1.762-.945 4.36 6.73L24.22 9z"
          id="successLarge_svg__a"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="successLarge_svg__b" fill="#fff">
          <use xlinkHref="#successLarge_svg__a" />
        </mask>
        <g mask="url(#successLarge_svg__b)" fill={color}>
          <path d="M0 0h36v36H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgSuccessLarge.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgSuccessLarge;
