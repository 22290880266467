import React from "react";
import PropTypes from "prop-types";

const SvgRight = props => {
  const { color, style, ...rest } = props;
  return (
    <svg width={12} height={6} {...rest} style={{ ...style, transform: "rotate(270deg)" }}>
      <defs>
        <path id="up_svg__a" d="M13.5 6.3l1.059 1.456-5.48 3.985L3.6 7.756 4.659 6.3l4.42 3.215z" />
      </defs>
      <g transform="translate(-3 -6)" fill="none" fillRule="evenodd">
        <mask id="up_svg__b" fill="#fff">
          <use xlinkHref="#up_svg__a" />
        </mask>
        <g mask="url(#up_svg__b)" fill={color}>
          <path d="M0 0h18v18H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgRight.propTypes = {
  color: PropTypes.string.isRequired,
  style: PropTypes.shape()
};

SvgRight.defaultProps = {
  style: null
};

export default SvgRight;
