import React from "react";
import PropTypes from "prop-types";

const SvgMyHistory = props => {
  const { color, ...rest } = props;
  return (
    <svg width={18} height={18} {...rest}>
      <defs>
        <path
          d="M3 5v10h3V5H3zM1 5V3h5V1h13v18H6v-2H1V5zm7-2v14h9V3H8zM4 6h2v2H4V6zm0 3h2v2H4V9zm0 3h2v2H4v-2zm6-7h5v2h-5V5zm0 4h5v2h-5V9zm0 4h5v2h-5v-2z"
          id="my-history_svg__a"
        />
      </defs>
      <g transform="translate(-1 -1)" fill="none" fillRule="evenodd">
        <mask id="my-history_svg__b" fill="#fff">
          <use xlinkHref="#my-history_svg__a" />
        </mask>
        <g mask="url(#my-history_svg__b)" fill={color}>
          <path d="M0 0h20v20H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgMyHistory.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgMyHistory;
