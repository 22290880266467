// Routing paths
export const LOGIN_ROUTE = "/login";
export const LOAN_ROUTE = "/loans/loan";
export const LOAN_SEARCH_ROUTE = "/loans/search";
export const WELCOME_ROUTE = "/welcome";
export const REPORT_LANDING_ROUTE = "/reports";
export const REPORT_GENERATE_ROUTE = "/reports/generate";
export const SYSTEM_ACTIVITY_ROUTE = "/system-activity";
export const DEALER_LIST_ROUTE = "/dealers";
export const DEALER_EDIT_ROUTE = "/dealers/edit";
export const PROVIDER_LIST_ROUTE = "/providers";
export const PROVIDER_EDIT_ROUTE = "/providers/edit";
export const ADMINISTRATION_ROUTE = "/admin";
export const USER_MANAGEMENT_ROUTE = "/admin/users";
export const CLIENT_MANAGEMENT_ROUTE = "/admin/clients";
export const ROLE_MANAGEMENT_ROUTE = "/admin/roles";
export const SYSTEM_MANAGEMENT_ROUTE = "/admin/system";
export const TASK_PROFILE_MANAGEMENT_ROUTE = "/admin/task-profiles";
export const DATA_MANAGEMENT_ROUTE = "/admin/data";
export const REMITTANCE_MANAGEMENT_ROUTE = "/admin/remittance";
export const UTILITY_MANAGEMENT_ROUTE = "/admin/utilities";
export const STATE_RULE_MANAGEMENT_ROUTE = "/admin/state-rules";
export const TASK_LIST_ROUTE = "/tasks";
export const TASK_ROUTE = "/tasks/task";

export const DEFAULT_DATE = "0001-01-01T00:00:00";
export const FOLLOW_UP_TASK = "FollowUp";
export const INVESTIGATION = "Investigation";
export const QUOTE_INVEST = "QuoteInvestigation";
export const OVERRIDE_REQUEST = "OverrideRequest";
export const PROCEEDS_INVEST = "ProceedsInvestigation";
export const FINALIZE_CANCEL_INVEST = "FinalizeCancellationInvestigation";

export const LOAN_TNX_REFUND_AMT_STATUS_REPORT =
  "Loan Transaction Refund Amount Status Report";
export const STATE_COMPLIANCE_RULES_REPORT = "State Compliance Rules Report";

export const IDPPEND = "IDPPEND";

export const tableHeaders = [
  "Active",
  "Name",
  "Priority",
  "WI Type",
  "Queue",
  "Filter Field",
  "Date Created",
  "Date Modified",
  "Created",
  "Modified",
];

export const DocumentTypes = {
  Name: "DocumentType",
  InsuranceSettlementCheck: "ISC",
  LoanContract: "LC",
  ProductContract: "PC",
  PoliceReportIncidentOccurrence: "PRIO",
  RepossessionAffidavit: "RA",
  DealerExport: "DealerExport",
  AckPak: "ACKPACK",
  CancellationLetter: "CXLL",
  Image: "IMAGE",
  Other: "OTHER",
  RefundPayment: "REFPAY",
};

export const FILE_TYPE = {
  EXCEL: "EXCEL",
  CSV: "CSV",
};

export const PROCEED_RELATE_CLASS_NAME =
  "ProductRefundLiabilityLib.BusinessObjects.Proceed";
