import React from "react";
import PropTypes from "prop-types";

const SvgExpandMedium = props => {
  const { color, ...rest } = props;
  return (
    <svg width={22} height={22} {...rest}>
      <defs>
        <path d="M13 11V7h-2v4H7v2h4v4h2v-4h4v-2h-4zM1 1h22v22H1V1z" id="expandMedium_svg__a" />
      </defs>
      <g transform="translate(-1 -1)" fill="none" fillRule="evenodd">
        <mask id="expandMedium_svg__b" fill="#fff">
          <use xlinkHref="#expandMedium_svg__a" />
        </mask>
        <g mask="url(#expandMedium_svg__b)" fill={color}>
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgExpandMedium.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgExpandMedium;
