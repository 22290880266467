import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useAuthValue } from "./context";

const Authorize = ({ required, requiresOneOf, children, unauthorized }) => {
  const [{ securityFunctions }] = useAuthValue();
  const [isAuthorized, setIsAuthorized] = useState(null);

  const hasPermission = async () => {
    let hasPerms = false;

    if (requiresOneOf.length) {
      hasPerms = requiresOneOf.some((perm) => securityFunctions.includes(perm));
    } else {
      hasPerms = securityFunctions.includes(required);
    }

    setIsAuthorized(hasPerms);
  };

  useEffect(() => {
    hasPermission();
  }, [isAuthorized]);

  if (securityFunctions.length) {
    if (isAuthorized !== null) {
      if (isAuthorized) {
        return <React.Fragment>{children}</React.Fragment>;
      }
      return <React.Fragment>{unauthorized}</React.Fragment>;
    }
    return <React.Fragment />;
  }
  return <React.Fragment />;
};

Authorize.propTypes = {
  required: PropTypes.string,
  requiresOneOf: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node.isRequired,
  unauthorized: PropTypes.node,
};

Authorize.defaultProps = {
  requiresOneOf: [],
  required: "",
  unauthorized: <React.Fragment />,
};

export default Authorize;
