import React from "react";
import PropTypes from "prop-types";

const SvgBoxUnchecked = props => {
  const { color, ...rest } = props;
  return (
    <svg width={18} height={18} {...rest}>
      <defs>
        <path d="M1 1h18v18H1V1zm2 2v14h14V3H3z" id="box-unchecked_svg__a" />
      </defs>
      <g transform="translate(-1 -1)" fill="none" fillRule="evenodd">
        <path fill="#FAFDFF" d="M1 1h18v18H1z" />
        <mask id="box-unchecked_svg__b" fill="#fff">
          <use xlinkHref="#box-unchecked_svg__a" />
        </mask>
        <g mask="url(#box-unchecked_svg__b)" fill={color}>
          <path d="M0 0h20v20H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgBoxUnchecked.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgBoxUnchecked;
