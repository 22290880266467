import React from "react";
import PropTypes from "prop-types";

const SvgSort = props => {
  const { color, ...rest } = props;
  return (
    <svg width={10} height={18} {...rest}>
      <defs>
        <path
          d="M10.325 19l-.896-.01L5 13.28l.452-.78h9.104l.444.79L10.325 19zm-.65-18l.896.01L15 6.72l-.452.78H5.444L5 6.71 9.675 1z"
          id="left_svg__a"
        />
      </defs>
      <g transform="translate(-5 -1)" fill="none" fillRule="evenodd">
        <mask id="left_svg__b" fill="#fff">
          <use xlinkHref="#left_svg__a" />
        </mask>
        <g mask="url(#left_svg__b)" fill={color}>
          <path d="M0 0h20v20H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgSort.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgSort;
