import React from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Bell,
  Bond,
  BoxChecked,
  BoxUnchecked,
  Building,
  Calendar,
  Caution,
  CautionLarge,
  Check,
  Car,
  Clock,
  ClockMedium,
  ClockLarge,
  Collapse,
  CollapseMedium,
  CpiBilling,
  Delete,
  Document,
  DoubleLeft,
  DoubleRight,
  Down,
  Download,
  Editing,
  Ellipsis,
  Error,
  ErrorLarge,
  Expand,
  ExpandMedium,
  Filter,
  Gear,
  House,
  Left,
  LeftArrow,
  LogOut,
  MyHistory,
  Person,
  PersonMedium,
  PersonLarge,
  Phone,
  PointOfSale,
  RadioActive,
  RadioInactive,
  Recovery,
  Refresh,
  Resources,
  Right,
  RightArrow,
  Search,
  Sort,
  Success,
  SuccessLarge,
  Tractor,
  Trash,
  Up,
  Upload,
  Vehicle,
  VerifyData
} from "./AlliedIcons";

export const AlliedIcons = {
  bell: "bell",
  bond: "bond",
  boxChecked: "boxChecked",
  boxUnchecked: "boxUnchecked",
  building: "building",
  calendar: "calendar",
  caution: "caution",
  cautionLarge: "cautionLarge",
  check: "check",
  car: "car",
  clock: "clock",
  clockMedium: "clockMedium",
  clockLarge: "clockLarge",
  collapse: "collapse",
  collapseMedium: "collapseMedium",
  cpiBilling: "cpiBilling",
  delete: "delete",
  document: "document",
  doubleLeft: "doubleLeft",
  doubleRight: "doubleRight",
  down: "down",
  download: "download",
  editing: "editing",
  ellipsis: "ellipsis",
  error: "error",
  errorLarge: "errorLarge",
  expand: "expand",
  expandMedium: "expandMedium",
  filter: "filter",
  gear: "gear",
  house: "house",
  left: "left",
  leftArrow: "leftArrow",
  logOut: "logOut",
  myHistory: "history",
  person: "person",
  personMedium: "personMedium",
  personLarge: "personLarge",
  phone: "phone",
  pointOfSale: "pos",
  radioActive: "radioActive",
  radioInactive: "radioInactive",
  recovery: "recovery",
  refresh: "refresh",
  resources: "resources",
  right: "right",
  rightArrow: "rightArrow",
  search: "search",
  sort: "sort",
  success: "success",
  successLarge: "successLarge",
  tractor: "tractor",
  trash: "trash",
  up: "up",
  upload: "upload",
  vehicle: "vehicle",
  verify: "verify"
};

export const AlliedColors = {
  default: "rgba(1, 112, 185, .85)",
  hover: "rgba(1, 112, 185, .75)",
  clicked: "rgba(1, 112, 185, 1)",
  disabled: "rgba(204, 209, 212, 1)",
  success: "rgb(110, 190, 74)",
  error: "rgb(235, 0, 40)",
  caution: "rgb(247, 141, 45)"
};

export default class IconFactory {
  static buildInfo(type, props) {
    const uuid = uuidv4();
    switch (type) {
      case AlliedIcons.bell:
        return <Bell {...props} />;
      case AlliedIcons.bond:
        return <Bond {...props} />;
      case AlliedIcons.boxChecked:
        return <BoxChecked {...props} />;
      case AlliedIcons.boxUnchecked:
        return <BoxUnchecked {...props} />;
      case AlliedIcons.building:
        return <Building {...props} />;
      case AlliedIcons.calendar:
        return <Calendar {...props} uuid={uuid} />;
      case AlliedIcons.caution:
        return <Caution {...props} />;
      case AlliedIcons.cautionLarge:
        return <CautionLarge {...props} />;
      case AlliedIcons.check:
        return <Check {...props} />;
      case AlliedIcons.car:
        return <Car {...props} />;
      case AlliedIcons.clock:
        return <Clock {...props} />;
      case AlliedIcons.clockMedium:
        return <ClockMedium {...props} />;
      case AlliedIcons.clockLarge:
        return <ClockLarge {...props} />;
      case AlliedIcons.collapse:
        return <Collapse {...props} />;
      case AlliedIcons.collapseMedium:
        return <CollapseMedium {...props} />;
      case AlliedIcons.cpiBilling:
        return <CpiBilling {...props} />;
      case AlliedIcons.delete:
        return <Delete {...props} />;
      case AlliedIcons.document:
        return <Document {...props} />;
      case AlliedIcons.doubleLeft:
        return <DoubleLeft {...props} />;
       case AlliedIcons.doubleRight:
          return <DoubleRight {...props} />;
       case AlliedIcons.down:
        return <Down {...props} />;
      case AlliedIcons.download:
        return <Download {...props} />;
      case AlliedIcons.editing:
        return <Editing {...props} />;
      case AlliedIcons.ellipsis:
        return <Ellipsis {...props} />;
      case AlliedIcons.error:
        return <Error {...props} />;
      case AlliedIcons.errorLarge:
        return <ErrorLarge {...props} />;
      case AlliedIcons.expand:
        return <Expand {...props} />;
      case AlliedIcons.expandMedium:
        return <ExpandMedium {...props} />;
      case AlliedIcons.filter:
        return <Filter {...props} />;
      case AlliedIcons.gear:
        return <Gear {...props} />;
      case AlliedIcons.house:
        return <House {...props} />;
      case AlliedIcons.left:
        return <Left {...props} />;
      case AlliedIcons.leftArrow:
        return <LeftArrow {...props} />;
      case AlliedIcons.logOut:
        return <LogOut {...props} />;
      case AlliedIcons.myHistory:
        return <MyHistory {...props} />;
      case AlliedIcons.person:
        return <Person {...props} />;
      case AlliedIcons.personMedium:
        return <PersonMedium {...props} />;
      case AlliedIcons.personLarge:
        return <PersonLarge {...props} />;
      case AlliedIcons.phone:
        return <Phone {...props} />;
      case AlliedIcons.pointOfSale:
        return <PointOfSale {...props} />;
      case AlliedIcons.radioActive:
        return <RadioActive {...props} />;
      case AlliedIcons.radioInactive:
        return <RadioInactive {...props} />;
      case AlliedIcons.recovery:
        return <Recovery {...props} />;
      case AlliedIcons.refresh:
        return <Refresh {...props} />;
      case AlliedIcons.resources:
        return <Resources {...props} />;
      case AlliedIcons.right:
        return <Right {...props} />;
      case AlliedIcons.rightArrow:
        return <RightArrow {...props} />;
      case AlliedIcons.search:
        return <Search {...props} />;
      case AlliedIcons.sort:
        return <Sort {...props} />;
      case AlliedIcons.success:
        return <Success {...props} />;
      case AlliedIcons.successLarge:
        return <SuccessLarge {...props} />;
      case AlliedIcons.tractor:
        return <Tractor {...props} />;
      case AlliedIcons.trash:
        return <Trash {...props} />;
      case AlliedIcons.up:
        return <Up {...props} />;
      case AlliedIcons.upload:
        return <Upload {...props} />;
      case AlliedIcons.vehicle:
        return <Vehicle {...props} />;
      case AlliedIcons.verify:
        return <VerifyData {...props} />;
      default:
        return <React.Fragment />;
    }
  }
}
