import React from "react";
import { Tab } from "semantic-ui-react";
import PropTypes from "prop-types";
import "./OspreyTab.scss";

/**
 * A consistent OspreyTab
 */
class OspreyTab extends React.Component {
  createPanes() {
    const { panes } = this.props;
    if (panes) {
      return panes.map(pane => ({
        menuItem: pane.menuItem,
        render: () => (
          <Tab.Pane attached={false} as="div">
            {pane.content}
          </Tab.Pane>
        )
      }));
    }

    return null;
  }

  render() {
    const { handleTabChange, panes, ...rest } = this.props;
    return (
      <Tab
        onTabChange={handleTabChange}
        menu={{ secondary: true, pointing: true }}
        panes={this.createPanes()}
        {...rest}
      />
    );
  }
}

OspreyTab.propTypes = {
  /** Header information */
  panes: PropTypes.arrayOf(
    PropTypes.shape({
      /** label for returning to previous content */
      menuItem: PropTypes.string,
      /** onclick event for returning to previous content */
      content: PropTypes.Any
    })
  ).isRequired
};

export default OspreyTab;
