import React from "react";
import PropTypes from "prop-types";

const SvgTractor = props => {
  const { color, ...rest } = props;
  return (
    <svg width={46} height={40} {...rest}>
      <defs>
        <path
          d="M42.372 27H28v-2h15.004l.816-2.585L41.697 21H18.636A11.989 11.989 0 0124 31c0 .681-.057 1.35-.166 2h10.188a6.495 6.495 0 015.478-3c.64 0 1.258.092 1.841.265L42.372 27zM24.618 5l2.336 4.673L28.82 19H33v-9l1-1h3v2h-2v8h7l.555.168 3 2 .399 1.133-2.789 8.83A6.5 6.5 0 1133.174 35h-9.857C21.67 39.66 17.225 43 12 43 5.373 43 0 37.627 0 31c0-3.584 1.571-6.801 4.063-9H4V5.586L6.586 3H26v2h-1.382zm-2.236 0H7.414L6 6.414v14.191a11.926 11.926 0 015-1.564V9h13.382l-2-4zm4.398 14l-1.6-8H21v8h5.78zM19 19v-8h-6v8h6zm-7 22c5.523 0 10-4.477 10-10s-4.477-10-10-10S2 25.477 2 31s4.477 10 10 10zm27.5 0a4.5 4.5 0 100-9 4.5 4.5 0 000 9zM12 37a6 6 0 110-12 6 6 0 010 12zm0-2a4 4 0 100-8 4 4 0 000 8z"
          id="tractor_svg__a"
        />
      </defs>
      <g transform="translate(0 -3)" fill="none" fillRule="evenodd">
        <mask id="tractor_svg__b" fill="#fff">
          <use xlinkHref="#tractor_svg__a" />
        </mask>
        <g mask="url(#tractor_svg__b)" fill={color}>
          <path d="M0 0h46v46H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgTractor.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgTractor;
