import React from "react";
import PropTypes from "prop-types";

const SvgPersonLarge = props => {
  const { color, ...rest } = props;
  return (
    <svg width={24} height={35} {...rest}>
      <defs>
        <path
          d="M18 14a7 7 0 110-14 7 7 0 010 14zm0-2a5 5 0 100-10 5 5 0 000 10zM8 33h20V20.28l-4.879-1.219-5.049 2.584-4.251-2.584L8 20.308V33zm14.879-16.061L30 18.719V35H6V18.692l8.18-1.753 3.976 2.416 4.723-2.416z"
          id="personLarge_svg__a"
        />
      </defs>
      <g transform="translate(-6)" fill="none" fillRule="evenodd">
        <mask id="personLarge_svg__b" fill="#fff">
          <use xlinkHref="#personLarge_svg__a" />
        </mask>
        <g mask="url(#personLarge_svg__b)" fill={color}>
          <path d="M0 0h36v36H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgPersonLarge.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgPersonLarge;
