﻿import React from "react";
import PropTypes from "prop-types";
import { Grid, Dropdown, Form, TextArea } from "semantic-ui-react";
import AlliedCheckBox from "../AlliedCheckBox";
import RefCodeSelect from "../RefCodeSelect/RefCodeSelect";
import RefDomains from "../RefCodeSelect/refdomains";

const OfflineCommunicationsModal = props => {
  const handleAddition = (e, { value }) => {
    const { setContactList, contactList } = props;
    setContactList([...contactList, { key: value, text: value, value }]);
  };

  const {
    isExternalNote,
    setIsExternalNote,
    note,
    setNote,
    contactList,
    contactPref,
    setContactPref,
    selectedContact,
    setSelectedContact,
    interactionType,
    setInteractionType
  } = props;

  return (
    <Grid columns={1}>
      <Grid.Column>
        <b className="padded-right-label">Contact Method: </b>
        <RefCodeSelect
          value={contactPref}
          name="contactPref"
          refDomain={RefDomains.ContactPreferenceType}
          sorted="asc"
          onChange={(e, { value }) => setContactPref(value)}
          id="OspreyComponentLibrary__OfflineCommunicationsModal--ContactMethod"
        />
      </Grid.Column>
      <Grid.Column>
        <b className="padded-right-label">Person Contacted: </b>
        <Dropdown
          value={selectedContact}
          placeholder=""
          search
          selection
          allowAdditions
          options={contactList}
          onChange={(e, { value }) => setSelectedContact(value)}
          onAddItem={handleAddition}
          id="OspreyComponentLibrary__OfflineCommunicationsModal--PersonContacted"
        />
      </Grid.Column>
      <Grid.Column>
        <b className="padded-right-label">Result:</b>
        <RefCodeSelect
          value={interactionType}
          name="contactPref"
          refDomain={RefDomains.InteractionResultType}
          sorted="asc"
          onChange={(e, { value }) => setInteractionType(value)}
          removeKeys={["Complete", "Error", "Pending"]}
          id="OspreyComponentLibrary__OfflineCommunicationsModal--Result"
        />
      </Grid.Column>
      <Grid.Column>
        <Form>
          <Grid>
            <Grid.Column width={8}></Grid.Column>
            <Grid.Column width={6} floated="right">
              {" "}
              <AlliedCheckBox
                label="External Note"
                checked={isExternalNote}
                onChange={() => setIsExternalNote(!isExternalNote)}
                id="OspreyComponentLibrary__OfflineCommunicationsModal--IsExternalNote"
              />
            </Grid.Column>
          </Grid>
          <TextArea name="note" placeholder="Enter notes here..." value={note} onChange={setNote} id="OspreyComponentLibrary__OfflineCommunicationsModal--Notes" />
        </Form>
      </Grid.Column>
    </Grid>
  );
};

OfflineCommunicationsModal.propTypes = {
  contactPref: PropTypes.string,
  setContactPref: PropTypes.func,
  selectedContact: PropTypes.string,
  contactList: PropTypes.arrayOf(PropTypes.string),
  setSelectedContact: PropTypes.func,
  interactionType: PropTypes.string,
  setInteractionType: PropTypes.func,
  isExternalNote: PropTypes.bool,
  setIsExternalNote: PropTypes.func,
  note: PropTypes.string,
  setNote: PropTypes.func,
  setContactList: PropTypes.func
};

OfflineCommunicationsModal.defaultProps = {
  contactPref: "",
  setContactPref: null,
  selectedContact: "",
  setSelectedContact: null,
  interactionType: "",
  setInteractionType: null,
  isExternalNote: false,
  setIsExternalNote: null,
  setNote: null,
  setContactList: null,
  note: "",
  contactList: []
};

export default OfflineCommunicationsModal;
