import React from "react";
import PropTypes from "prop-types";

const SvgResources = props => {
  const { color, ...rest } = props;
  return (
    <svg width={12} height={18} {...rest}>
      <defs>
        <path
          d="M16 3v12h-4v3l-1.447.894L9 18.118l-1.553.776L6 18v-3H4V1h12v2zm-2 0H6v10h8V3zm-4 12H8v1.382l.553-.276h.894l.553.276V15zM8 4h2v8H8V4z"
          id="resources_svg__a"
        />
      </defs>
      <g transform="translate(-4 -1)" fill="none" fillRule="evenodd">
        <mask id="resources_svg__b" fill="#fff">
          <use xlinkHref="#resources_svg__a" />
        </mask>
        <g mask="url(#resources_svg__b)" fill={color}>
          <path d="M0 0h20v20H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgResources.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgResources;
