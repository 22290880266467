import React from "react";
import PropTypes from "prop-types";

const SvgSearch = props => {
  const { color, style, ...rest } = props;
  return (
    <svg width={18} height={19} {...rest} style={{ ...style, transform: "rotate(45deg)" }}>
      <defs>
        <path
          d="M10.081 16.52v5.061h-2V16.52c-3.941-.493-7-3.864-7-7.938 0-4.412 3.588-8 8-8v.89-.89c4.413 0 8 3.588 8 8 0 4.074-3.058 7.445-7 7.938zm-1-14.939v.867c-3.307 0-6 2.722-6 6.067 0 3.344 2.693 6.066 6 6.066 3.308 0 6-2.722 6-6.066 0-3.345-2.692-6.067-6-6.067v-.867z"
          id="search_svg__a"
        />
      </defs>
      <g transform="translate(-1 -1)" fill="none" fillRule="evenodd">
        <mask id="search_svg__b" fill="#fff">
          <use xlinkHref="#search_svg__a" />
        </mask>
        <g mask="url(#search_svg__b)" fill={color}>
          <path d="M0 0h20v20H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgSearch.propTypes = {
  color: PropTypes.string.isRequired,
  style: PropTypes.shape()
};

SvgSearch.defaultProps = {
  style: null
};

export default SvgSearch;
