import React from "react";
import PropTypes from "prop-types";

const SvgPerson = props => {
  const { color, ...rest } = props;
  return (
    <svg width={13} height={18} {...rest}>
      <defs>
        <path
          d="M12.888 9.968l4.112.685V19H4v-8.367l4.668-.667 1.878.939 2.342-.937zM15 17v-4.653l-1.888-.315-2.658 1.063-2.122-1.06L6 12.366V17h9zm-4.5-8a4 4 0 110-8 4 4 0 010 8zm0-2a2 2 0 100-4 2 2 0 000 4z"
          id="person_svg__a"
        />
      </defs>
      <g transform="translate(-4 -1)" fill="none" fillRule="evenodd">
        <mask id="person_svg__b" fill="#fff">
          <use xlinkHref="#person_svg__a" />
        </mask>
        <g mask="url(#person_svg__b)" fill={color}>
          <path d="M0 0h20v20H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgPerson.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgPerson;
