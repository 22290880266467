import React, { createContext, useContext, useReducer, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { AuthActions } from "../reducer";

export const AuthContext = createContext();
export const AuthProvider = ({ reducer, initialState, children }) => (
  <AuthContext.Provider value={useReducer(reducer, initialState)}>
    <AuthWrapper>{children}</AuthWrapper>
  </AuthContext.Provider>
);
export const useAuthValue = () => useContext(AuthContext);

export function AuthWrapper({ children }) {
  const [, dispatch] = useAuthValue();

  useEffect(() => {
    const fetchSecurityFunctions = async () => {
      try {
        const { data } = await axios.get(
          "/api/account/GetUserSecurityFunctions",
          {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
            },
          }
        );

        // TODO: Re-work API response structure to not have two completely different bodies depending on auth state
        if (data.isAuth === false) {
          dispatch({
            type: AuthActions.SET_AUTH,
            payload: { isAuthenticated: false, securityFunctions: [] },
          });
          return;
        }

        const mapped = data.map((s) => s.securityCode);
        dispatch({
          type: AuthActions.SET_AUTH,
          payload: { isAuthenticated: true, securityFunctions: mapped },
        });
        // setAuthenticated(true);
        // setSecFnc(mapped);
      } catch (err) {
        console.log(err);
        // User is not logged in
        dispatch({
          type: AuthActions.SET_AUTH,
          payload: { isAuthenticated: false, securityFunctions: [] },
        });
        // setAuthenticated(false);
        // setSecFnc([]);
        return;
      }
    };
    try {
      fetchSecurityFunctions();
    } catch (err) {
      console.log("caught err from outer call");
    }
  }, []);

  return <React.Fragment>{children}</React.Fragment>;
}

AuthWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

AuthProvider.propTypes = {
  reducer: PropTypes.func.isRequired,
  initialState: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
    securityFunctions: PropTypes.array,
  }),
  children: PropTypes.node.isRequired,
};

AuthProvider.defaultProps = {
  initialState: {
    isAuthenticated: false,
    securityFunctions: [],
  },
};
