﻿import React from "react";
import PropTypes from "prop-types";

const SvgDoubleLeft = props => {
   const { color, style, ...rest } = props;
   return (
      <svg width={12} height={6} {...rest}>
         <defs>
            <path id="doubleLeft_svg__a" d="M8.5547002,5.16794971 C8.98140541,5.45241985 9.11895264,6.00808057 8.89114224,6.45393368 L8.83205029,6.5547002 L7.202,9 L8.83205029,11.4452998 C9.11652043,11.872005 9.02977145,12.4378254 8.64800296,12.7617627 L8.5547002,12.8320503 C8.12799499,13.1165204 7.56217462,13.0297714 7.23823728,12.648003 L7.16794971,12.5547002 L5.16794971,9.5547002 C4.9688982,9.25612294 4.94678137,8.87718843 5.1015992,8.560754 L5.16794971,8.4452998 L7.16794971,5.4452998 C7.47430216,4.98577112 8.09517151,4.86159725 8.5547002,5.16794971 Z M12.5547002,5.16794971 C12.9814054,5.45241985 13.1189526,6.00808057 12.8911422,6.45393368 L12.8320503,6.5547002 L11.202,9 L12.8320503,11.4452998 C13.1165204,11.872005 13.0297714,12.4378254 12.648003,12.7617627 L12.5547002,12.8320503 C12.127995,13.1165204 11.5621746,13.0297714 11.2382373,12.648003 L11.1679497,12.5547002 L9.16794971,9.5547002 C8.9688982,9.25612294 8.94678137,8.87718843 9.1015992,8.560754 L9.16794971,8.4452998 L11.1679497,5.4452998 C11.4743022,4.98577112 12.0951715,4.86159725 12.5547002,5.16794971 Z" />
         </defs>
         <g transform="translate(-3 -6)" fill="none" fillRule="evenodd">
            <mask id="doubleLeft_svg__b" fill="#fff">
               <use xlinkHref="#doubleLeft_svg__a" />
            </mask>
            <g mask="url(#doubleLeft_svg__b)" fill={color}>
               <path d="M0 0h18v18H0z" />
            </g>
         </g>
      </svg>
   );
};

SvgDoubleLeft.propTypes = {
   color: PropTypes.string.isRequired,
   style: PropTypes.shape()
};

SvgDoubleLeft.defaultProps = {
   style: null
};

export default SvgDoubleLeft;
