import React from "react";
import PropTypes from "prop-types";

const SvgRightArrow = props => {
  const { color, ...rest } = props;
  return (
    <svg width={19} height={11} {...rest}>
      <defs>
        <path
          d="M16.156 9L14 6.228 15.579 5l3.977 5.114-3.977 5.114L14 14l2.333-3H1V9h15.156z"
          id="right-arrow_svg__a"
        />
      </defs>
      <g transform="translate(-1 -5)" fill="none" fillRule="evenodd">
        <mask id="right-arrow_svg__b" fill="#fff">
          <use xlinkHref="#right-arrow_svg__a" />
        </mask>
        <g mask="url(#right-arrow_svg__b)" fill={color}>
          <path d="M0 0h20v20H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgRightArrow.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgRightArrow;
