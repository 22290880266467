import React from "react";
import PropTypes from "prop-types";

const SvgPhone = props => {
  const { color, ...rest } = props;
  return (
    <svg width={18} height={18} {...rest}>
      <defs>
        <path
          d="M13.775 10.5h1.414l3.5 3.5v1.414l-3 3-.928.268C7.046 16.93 2.409 12.292 1 4.894L1.275 4l3-3H5.69l3.5 3.5v1.414L8.162 6.942c.884 1.938 2.41 3.453 4.611 4.56l1.002-1.002zm2.793 4.207l-2.086-2.086-.793.793-1.113.207c-3.236-1.438-5.432-3.634-6.53-6.563L6.276 6l.792-.793-2.086-2.086L3.07 5.034c1.33 6.131 5.155 9.953 11.602 11.57l1.897-1.897z"
          id="phone_svg__a"
        />
      </defs>
      <g transform="translate(-1 -1)" fill="none" fillRule="evenodd">
        <mask id="phone_svg__b" fill="#fff">
          <use xlinkHref="#phone_svg__a" />
        </mask>
        <g mask="url(#phone_svg__b)" fill={color}>
          <path d="M0 0h20v20H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgPhone.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgPhone;
