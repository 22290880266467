import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./LinkButton.scss";

const LinkButton = props => {
  const { position, disabled, children, ...buttonProps } = props;

  const buttonClass = classNames({
    [`${position}-btn`]: position,
    "link-btn": true,
    "btn-disabled": disabled
  });

  return (
    <button {...buttonProps} className={buttonClass} disabled={disabled} type="button">
      {children}
    </button>
  );
};

LinkButton.propTypes = {
  position: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired
};

LinkButton.defaultProps = {
  position: null,
  disabled: false
};
export default LinkButton;
