import React from "react";
import PropTypes from "prop-types";

const SvgRadioActive = props => {
  const { color, ...rest } = props;
  return (
    <svg width={18} height={18} {...rest}>
      <defs>
        <path
          d="M10 2V1c4.964 0 9 4.036 9 9 0 4.963-4.037 9-9 9s-9-4.037-9-9c0-4.964 4.036-9 9-9v1zm0 0v1c-3.86 0-7 3.14-7 7 0 3.859 3.141 7 7 7s7-3.141 7-7c0-3.86-3.14-7-7-7V2zm0 13a5 5 0 110-10 5 5 0 010 10z"
          id="radio-active_svg__a"
        />
      </defs>
      <g transform="translate(-1 -1)" fill="none" fillRule="evenodd">
        <circle fill="#FAFDFF" cx={10} cy={10} r={9} />
        <mask id="radio-active_svg__b" fill="#fff">
          <use xlinkHref="#radio-active_svg__a" />
        </mask>
        <g mask="url(#radio-active_svg__b)" fill={color}>
          <path d="M0 0h20v20H0z" />
        </g>
        <circle mask="url(#radio-active_svg__b)" cx={10} cy={10} r={9} />
      </g>
    </svg>
  );
};

SvgRadioActive.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgRadioActive;
