import styled from "styled-components";

export const Arrow = styled.img`
  height: 31px;
  width: 31px;
  cursor: pointer;
  border: 1px solid #ebf1f5;
  margin-left: 9.5px;
  margin-right: 9.5px;
  margin-top: 8px;
`;

export const ArrowLeft = styled(Arrow)`
  transform: rotate(90deg);
`;

export const ArrowRight = styled(Arrow)`
  transform: rotate(270deg);
`;

export const CalendarContainer = styled.div`
  font-size: 5px;
  box-shadow: 0 2px 8px 2px rgba(0, 89, 148, 0.06);
  min-height: 203px;
  width: 227px;
`;

export const CalendarHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CalendarGrid = styled.div`
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: auto;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template: repeat(7, auto) / repeat(7, auto);
`;

export const CalendarMonth = styled.div`
  color: #031c2c;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.39px;
  line-height: 17px;
  text-align: center;
  margin-top: 8px;
`;

export const CalendarCell = styled.div`
  text-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  letter-spacing: 0.1rem;
  padding: 0.6em 0.25em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-grid-column: ${props => (props.index % 7) + 1};
  -ms-grid-column-span: 1;
  grid-column: ${props => (props.index % 7) + 1} / span 1;
`;

export const CalendarDay = styled(CalendarCell)`
  height: 18px;
  width: 26px;
  color: #6a8b9f;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 10px;
`;

export const CalendarDate = styled(CalendarCell)`
  height: 30px;
  border: 1px solid #ebf1f5;
  color: #031c2c;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10px;
  font-weight: 300;
  line-height: 15px;
  text-align: center;
  -ms-grid-row: ${props => Math.floor(props.index / 7) + 2};
  -ms-grid-row-span: 1;
  grid-row: ${props => Math.floor(props.index / 7) + 2} / span 1;
  cursor: pointer;
  :hover {
    border-color: #0b77bc;
  }
`;

export const HighlightedCalendarDate = styled(CalendarDate)`
  color: #fff !important;
  background: #0b77bc !important;
  position: relative;
  ::before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border: 2px solid #0b77bc;
  }
`;

export const TodayCalendarDate = styled(HighlightedCalendarDate)`
  color: #0b77bc !important;
  background: transparent !important;
  ::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    border-bottom: 0.75em solid #0b77bc;
    border-left: 0.75em solid transparent;
    border-top: 0.75em solid transparent;
  }
  :hover {
    color: #fff !important;
    background-color: #0b77bc !important;
  }
`;

export const DisabledDate = styled(CalendarDate)`
  pointer-events: none;
  cursor: not-allowed;
  color: #66666680;
  border: 1px solid #99999980;
  background-color: #cccccc80;
  :hover {
    border-color: #99999980;
  }
`;