import React from "react";
import PropTypes from "prop-types";

const SvgCheck = props => {
  const { color, name, ...rest } = props;
  return (
    <svg width={13} height={14} {...rest}>
      <defs>
        <path
          id={"check_svg__a" + name}
          d="M15.22 3l1.78.912-6.967 12.98-1.771.017L4 9.955l1.762-.946 3.36 5.275z"
        />
      </defs>
      <g transform="translate(-4 -3)" fill="none" fillRule="evenodd">
        <mask id={"check_svg__b" + name} fill="#fff">
          <use xlinkHref={"#check_svg__a" + name} />
        </mask>
        <g mask={"url(#check_svg__b" + name + ")"} fill={color}>
          <path d="M0 0h20v20H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgCheck.propTypes = {
  color: PropTypes.string.isRequired,
  name: PropTypes.string
};

export default SvgCheck;
