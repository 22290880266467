import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import AlliedButtonGroup from "./AlliedButtonGroup";
import "./AlliedButton.scss";

class AlliedButton extends React.Component {
  static Group = AlliedButtonGroup;

  render() {
    const { color, position, inverted, children, type, disabled, ...buttonProps } = this.props;
    const buttonClass = classNames({
      [`btn-${color}`]: true,
      [`${position}-btn`]: position,
      "cp-btn": true,
      "btn-outline": inverted,
      "btn-disabled": disabled
    });

    return (
      // eslint-disable-next-line react/button-has-type
      <button {...buttonProps} className={buttonClass} type={type} disabled={disabled}>
        {children}
      </button>
    );
  }
}

AlliedButton.propTypes = {
  position: PropTypes.string,
  color: PropTypes.string,
  inverted: PropTypes.bool,
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool
};

AlliedButton.defaultProps = {
  position: null,
  inverted: false,
  color: "green",
  type: "button",
  disabled: false
};

export default AlliedButton;
