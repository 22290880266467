import React from "react";
import PropTypes from "prop-types";

const SvgGear = props => {
  const { color, ...rest } = props;
  return (
    <svg width={18} height={18} {...rest}>
      <defs>
        <path
          d="M6.896 2.624A7.946 7.946 0 019 2.062V1h2v1.062a7.946 7.946 0 012.104.562l.53-.918 1.732 1-.531.92c.58.44 1.098.959 1.54 1.54l.92-.532 1 1.732-.92.53c.279.661.471 1.367.563 2.104H19v2h-1.062a7.946 7.946 0 01-.562 2.104l.918.53-1 1.732-.92-.531c-.44.58-.959 1.098-1.54 1.54l.532.92-1.732 1-.53-.92c-.661.279-1.367.471-2.104.563V19H9v-1.062a7.946 7.946 0 01-2.104-.562l-.53.918-1.732-1 .531-.92a8.047 8.047 0 01-1.54-1.54l-.92.532-1-1.732.92-.53A7.946 7.946 0 012.061 11H1V9h1.062a7.946 7.946 0 01.562-2.104l-.918-.53 1-1.732.92.531c.44-.58.959-1.098 1.54-1.54l-.532-.92 1.732-1 .53.92zM7.1 4.746l-.19.11A6.03 6.03 0 004.856 6.91l-.11.19A5.973 5.973 0 004 10c0 1.052.27 2.04.746 2.9l.11.19a6.03 6.03 0 002.054 2.054l.19.11c.86.475 1.848.746 2.9.746 1.052 0 2.04-.27 2.9-.746l.19-.11a6.03 6.03 0 002.054-2.054l.11-.19c.475-.86.746-1.848.746-2.9 0-1.052-.27-2.04-.746-2.9l-.11-.19a6.03 6.03 0 00-2.054-2.054l-.19-.11A5.973 5.973 0 0010 4c-1.052 0-2.04.27-2.9.746zM10 13a3 3 0 110-6 3 3 0 010 6z"
          id="gear_svg__a"
        />
      </defs>
      <g transform="translate(-1 -1)" fill="none" fillRule="evenodd">
        <mask id="gear_svg__b" fill="#fff">
          <use xlinkHref="#gear_svg__a" />
        </mask>
        <g mask="url(#gear_svg__b)" fill={color}>
          <path d="M0 0h20v20H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgGear.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgGear;
