import React from "react";
import PropTypes from "prop-types";

const SvgCollapse = props => {
  const { color, ...rest } = props;
  return (
    <svg width={18} height={18} {...rest}>
      <defs>
        <path d="M1 1h18v18H1V1zm4 8v2h10V9H5z" id="collapse_svg__a" />
      </defs>
      <g transform="translate(-1 -1)" fill="none" fillRule="evenodd">
        <mask id="collapse_svg__b" fill="#fff">
          <use xlinkHref="#collapse_svg__a" />
        </mask>
        <g mask="url(#collapse_svg__b)" fill={color}>
          <path d="M0 0h20v20H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgCollapse.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgCollapse;
