import React from "react";
import PropTypes from "prop-types";

const SvgBoxChecked = props => {
  const { color, ...rest } = props;
  return (
    <svg width={18} height={18} {...rest}>
      <defs>
        <path
          d="M1 1h18v18H1V1zm2 2v14h14V3H3zm9.566 2.654l1.78.912-4.313 8.414-1.771.017-2.616-4.875 1.762-.945 1.715 3.194 3.443-6.717z"
          id="box-checked_svg__a"
        />
      </defs>
      <g transform="translate(-1 -1)" fill="none" fillRule="evenodd">
        <path fill="#FAFDFF" d="M1 1h18v18H1z" />
        <mask id="box-checked_svg__b" fill="#fff">
          <use xlinkHref="#box-checked_svg__a" />
        </mask>
        <g mask="url(#box-checked_svg__b)" fill={color}>
          <path d="M0 0h20v20H0z" />
        </g>
      </g>
    </svg>
  );
};

SvgBoxChecked.propTypes = {
  color: PropTypes.string.isRequired
};

export default SvgBoxChecked;
